import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {first, switchMapTo, skipWhile, delay} from 'rxjs/operators';

import {CodePromptComponent} from '@/components/code-prompt/code-prompt.component';
import {ProviderType} from '@/config';
import {AuthStoreService} from '@/services/auth.service';
import {ParentStoreService} from '@/services/parent.service';
import {environment} from 'src/environments/environment';

// eslint-disable-next-line max-len
const emailPattern =
	/^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	loading = false;
	email = new FormControl('', [Validators.required, Validators.pattern(emailPattern)]);
	password = new FormControl('', [Validators.required]);
	confirmPassword = new FormControl('', [
		Validators.required,
		(control) => (control.value === this.password.value ? null : {unmatch: control.value}),
	]);

	screen: 'forgot' | 'login' | 'reset';
	successfulReset = false;
	returnURI = '';

	token = '';

	hidePass = false;

	constructor(
		private store: AuthStoreService,
		private route: ActivatedRoute,
		private router: Router,
		private dialog: MatDialog,
		private parentStore: ParentStoreService,
	) {}

	getError() {
		const email = this.email.hasError('required')
			? 'The Email Address field is missing.'
			: this.email.hasError('email')
			? 'Invalid Email.'
			: '';
		const password = this.password.hasError('required')
			? 'The Password field is missing.'
			: this.password.hasError('invalid')
			? 'Your email or password was entered incorrectly.'
			: this.password.hasError('token')
			? 'Your token is invalid or expired'
			: 'Password should fulfill all the requirements';
		const confirm = this.confirmPassword.hasError('unmatch') ? "Password doesn't match" : '';
		return {email, password, confirm};
	}

	revalidate() {
		if (this.screen === 'reset') {
			this.confirmPassword.updateValueAndValidity();
		}
	}

	openCodeModal() {
		this.dialog
			.open(CodePromptComponent, {
				position: {
					top: '0',
				},
				maxWidth: '450px',
				width: '100vw',
				height: '350px',
				data: {
					email: this.email.value,
					type: 'login',
				},
			})
			.afterClosed()
			.subscribe((state) => state && this.router.navigateByUrl(this.returnURI));
	}

	async onSubmit() {
		if (this.loading) {
			return;
		}
		if (this.screen === 'forgot') {
			if (this.email.valid) {
				this.loading = true;
				this.store.reset(this.email.value).subscribe(() => {
					this.successfulReset = true;
					this.loading = false;
				});
			}
		} else if (this.screen === 'login') {
			if (this.email.valid && this.password.valid) {
				this.loading = true;

				this.store
					.login(this.email.value, this.password.value)
					.pipe(
						delay(100),
						switchMapTo(this.parentStore.loading),
						skipWhile((loading) => loading),
						first(),
					)
					.subscribe(
						() => {
							const type = this.store.getType();
							const questionnaire = this.parentStore.getUserQuestionnaire();
							if (this.returnURI) this.router.navigateByUrl(this.returnURI);
							else if (type === 'provider') this.router.navigateByUrl('/providers/deals');
							else if (type === 'parent'){
								this.router.navigateByUrl(questionnaire[ProviderType.SURROGACY_AGENCY] ? '/surrogacy' : '/egg-donor').then(() => {
									window.location.reload();
								});
							}
							else if (type === 'admin') this.router.navigateByUrl('/chat/');
							this.loading = false;
						},
						(error) => {
							this.loading = false;
							if (error instanceof HttpErrorResponse) {
								if (error.status === 401 || error.status === 404) this.password.setErrors({invalid: true});
								else this.openCodeModal();
							} else throw error;
						},
					);
			}
		} else {
			if (this.successfulReset) {
				this.router.navigateByUrl('/login');
			} else if (this.password.valid && this.confirmPassword.valid) {
				this.loading = true;
				this.store.resetWithToken(this.token, this.password.value).subscribe(
					() => {
						this.successfulReset = true;
						this.loading = false;
					},
					(e) => {
						if (e.status === 400) {
							this.password.setErrors({token: true});
							this.loading = false;
						}
					},
				);
			}
		}
	}

	ngOnInit(): void {
		this.route.queryParams.pipe(first()).subscribe((params) => (this.returnURI = params['r'] || ''));
		this.route.url.pipe(first()).subscribe((segments) => {
			this.screen =
				segments[segments.length - 1].path === 'forgot' ? 'forgot' : segments[segments.length - 1].path === 'reset' ? 'reset' : 'login';
			if (this.screen === 'reset') {
				this.password.setValidators([
					Validators.required,
					Validators.minLength(8),
					(control) => ((control.value || '').match(/[A-Z]+/) ? null : {capital: {value: control.value}}),
					(control) => ((control.value || '').match(/[a-z]+/) ? null : {lower: {value: control.value}}),
					(control) => ((control.value || '').match(/[0-9]+/) ? null : {number: {value: control.value}}),
				]);
			}
		});
		this.route.queryParams.pipe(first()).subscribe((params) => {
			this.token = params.token || '';
		});
	}
}
