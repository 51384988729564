<app-compare-bar *ngIf="(checked$ | async) && (comparing$ | async)?.length" type="egg_agency" baseUrl="../../">
</app-compare-bar>

<div class="profile-container">
    <div class="images-container">
        <button class="backward" (click)="onScroll('backward')" mat-icon-button>
            <app-insvg name="chevron" link="assets/chevron-right.svg"></app-insvg>
        </button>
        <div class="images" #carousel>
            <video #video *ngIf="donor?.video" muted autoplay (click)="onImageOpen(-1)">
                <source src="/images/{{donor.video}}">
            </video>
            <img *ngFor="let item of images; let i = index" (click)="onImageOpen(i)" [src]="'/images/' + item" />
        </div>
        <button class="forward" (click)="onScroll('forward')" mat-icon-button>
            <app-insvg name="chevron" link="assets/chevron-right.svg"></app-insvg>
        </button>
    </div>
    <ng-container *ngIf="forPDF; else tabView">
        <div class="profile-description">
            <ng-container [ngTemplateOutlet]="sectionHeader"></ng-container>
            <div *ngFor="let tab of tabs" class="profile-description-section">
                <div class="profile-description-section-header">{{tab.title}}</div>
                <ng-container [ngTemplateOutlet]="tab.template"></ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #tabView>
        <mat-tab-group mat-align-tabs="center" color="primary" backgroundColor="primary" [selectedIndex]="selectedIndex" (selectedIndexChange)="onTabChange($event)">
            <mat-tab *ngFor="let tab of tabs" [label]="tab.title"></mat-tab>
        </mat-tab-group>
        <div *ngIf="donor" class="profile-description">
            <a class="back-to-search mobile-none" routerLink="/egg-donor">
                <mat-icon>arrow_back</mat-icon>Back to the search
            </a>
            <ng-container [ngTemplateOutlet]="sectionHeader"></ng-container>
            <div *ngFor="let tab of tabs" [id]="tab.id">
                <ng-container [ngTemplateOutlet]="tab.template"></ng-container>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #actions>
    <div class="actions">
        <app-heart [value]="isInFavourite$ | async" (valueChange)="onChange('stared')" matTooltip="Add to Favorites">
        </app-heart>
        <button *ngIf="!stripe_subscription_can_subscribe " mat-flat-button data-an-category="user" data-an-action="contact_agency" data-an-label="egg_donor" data-an-value="profile" color="accent" class="interested" [class.isStatic]="showContactAgencyAsStatic"
            (click)="onChange('interested')">
            {{ (hasChat$ | async) ? 'Continue Messaging' : 'Contact Agency' }}
        </button>
        <button *ngIf="stripe_subscription_can_subscribe && stripe_subscription_status !== 'active'" mat-flat-button data-an-category="user" data-an-action="contact_agency" data-an-label="egg_donor" data-an-value="profile" color="accent" class="interested" [class.isStatic]="showContactAgencyAsStatic"
            (click)="onChange('premium')">
            {{ (hasChat$ | async) ? 'Continue Messaging' : 'Contact Agency' }}
        </button>
        <button *ngIf="stripe_subscription_can_subscribe && stripe_subscription_status === 'active'" mat-flat-button data-an-category="user" data-an-action="contact_agency" data-an-label="egg_donor" data-an-value="profile" color="accent" class="interested" [class.isStatic]="showContactAgencyAsStatic"
            (click)="onChange('interested')">
            {{ (hasChat$ | async) ? 'Continue Messaging' : 'Contact Agency' }}
        </button>
        <button class="share mobile-none" matTooltip="Share" (click)="onChange('share', $event)" mat-mini-fab>
            <app-insvg name="export" link="assets/export.svg"></app-insvg>
        </button>
        <button class="mobile-none" [disabled]="loadingPDF" matTooltip="Show profile as a PDF (may take few seconds)" mat-mini-fab (click)="onChange('print')" color="accent">
            <app-insvg name="print" link="assets/pdf.svg"></app-insvg>
            <mat-progress-spinner *ngIf="loadingPDF" mode="indeterminate" color="accent" diameter="46">
            </mat-progress-spinner>
        </button>
        <div class="checkbox">
            <app-checkbox [value]="checked$ | async" (valueChange)="onChange('checked')" [size]="16">{{(checked$ | async) ? 'Remove from Compare' : 'Add to Compare'}}</app-checkbox>
        </div>
    </div>
</ng-template>

<ng-template #overlay>
    <div class="overlay-container">
        <button class="backward" (click)="onImageChange('backward')" mat-icon-button>
            <app-insvg name="chevron" link="assets/chevron-right.svg"></app-insvg>
        </button>
        <video *ngIf="overlayImage === -1; else imageRef" autoplay controls>
            <source src="/images/{{donor.video}}">
        </video>
        <ng-template #imageRef>
            <img src="/images/{{images[overlayImage]}}">
        </ng-template>
        <button mat-icon-button (click)="overlayRef.dispose()" class="close">
            <mat-icon [inline]="true">close</mat-icon>
        </button>
        <button class="forward" (click)="onImageChange('forward')" mat-icon-button>
            <app-insvg name="chevron" link="assets/chevron-right.svg"></app-insvg>
        </button>
    </div>
</ng-template>

<ng-template #sectionHeader>
    <div class="profile-header">
        <div class="id">Donor #{{('0000' + donor.id).slice(-6)}}</div>
        <div class="other" [class.availability]="donor.previous_donor === 'Yes'">
            <div class="avail">
                <span class="sign" [ngClass]="{'green': donor.availability === 'Available', 'warn': donor.availability === 'In Cycle', 'error': donor.deleted_at || donor.availability === 'Under Review'}"></span>
                <span>{{donor.deleted_at ? 'Unavailable, deleted at ' + (donor.deleted_at | date) :
                    donor.availability}}</span>
            </div>
            <div *ngIf="donor.previous_donor === 'Yes'" class="prevD">Previous Donor</div>
            <div *ngIf="donor.location" class="loc">{{donor.location}}</div>
            <div *ngIf="isSmallScreen" class="donor-type">
                <span>{{ donor.donor_type === 'frozen_eggs' ? "Frozen Eggs" : "Fresh Donor" }}</span>
            </div>
            <div class="total" *ngIf="donor.donor_type !== 'frozen_eggs'" (click)="onTabChange(1)" >
                <span>Total Cost: {{donor.total_compensation}}</span>
            </div>
            <div class="total" *ngIf="donor.donor_type === 'frozen_eggs'">
                <span>Egg Lot Cost: {{donor.total_compensation}}</span>
            </div>
            <div *ngIf="(donor.formattedDonationTypes || []).length > 0" class="donation-type">Types of Donation: {{ donor.formattedDonationTypes.join(', ') }}</div>
            <div *ngIf="!isSmallScreen" class="donor-type">
                <span>{{ donor.donor_type === 'frozen_eggs' ? "Frozen Eggs" : "Fresh Donor" }}</span>
            </div>
        </div>
        <div *ngIf="isSmallScreen" class="actions-mobile">
            <ng-container *ngTemplateOutlet="actions"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #summary>
    <section class="summary-container" *ngIf="donor.version === 2" id="about">
        <div class="card summary" >
            <div class="card-header">Summary</div>
            <div class="card-content card-content-flex">
                <div class="card-column">
                    <div class="field">
                        <div class="question">Age</div>
                        <div class="answer">{{ donor.age + ' Years Old' }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Eye Color</div>
                        <div class="answer">{{ donor.eye_color || '-' | titlecase }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Hair Color</div>
                        <div class="answer">{{ donor.hair_color || '-' | titlecase }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Race</div>
                        <div class="answer">{{ donor.race || '-' | titlecase }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Ethnicity</div>
                        <div class="answer">{{ donor.ethnicity || '-' | titlecase }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Religion</div>
                        <div class="answer">{{ donor.religion || '-' | titlecase }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Height</div>
                        <div class="answer">{{ useHeight(donor.height) }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Weight</div>
                        <div class="answer">{{ useWeight(donor.weight) }}</div>
                    </div>
                </div>
                <div class="card-column">

                    <div class="field">
                        <div class="question">Education Level</div>
                        <div class="answer">{{ donor.education_level || '-' }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Location</div>
                        <div class="answer">{{ donor.location }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Donation types</div>
                        <div class="answer">{{ donor.formattedDonationTypes || '-' }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Relationship Status</div>
                        <div class="answer">{{ donor.relationship_status || '-' }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Occupation</div>
                        <div class="answer">{{ donor.occupation || '-' }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Egg Donor Compensation</div>
                        <div class="answer">{{ donor.formattedDonorCompensation || utils.parseCost(donor.egg_donor_compensation) || '-' }}</div>
                    </div>
                    <div class="field">
                        <div class="question">Total Compensation</div>
                        <div class="answer">{{ utils.parseCost(donor.total_compensation) || '-' }}</div>
                    </div>
                </div>
            </div>
            <div class="card-content freetext" style="white-space: pre-line" *ngIf="donor['why_i_want_to_become_a_donor'] && donor['why_i_want_to_become_a_donor'] !== '[1]'">
                <div class="field">
                    <p class="question">Why I Want to Become a Donor?</p>
                    <p>{{utils.toCapitalizedCase(donor['why_i_want_to_become_a_donor'])}}</p>
                </div>
            </div>
            <div class="card-content freetext" style="white-space: pre-line" *ngIf="donor['a_message_to_intended_parents'] && donor['a_message_to_intended_parents'] !== '[1]'">
                <div class="field">
                    <p class="question">A Message to Intended Parents:</p>
                    <p>{{utils.toCapitalizedCase(donor['a_message_to_intended_parents'])}}</p>
                </div>
            </div>
            <div class="card-content freetext" style="white-space: pre-line" *ngIf="donor.summary">
                {{ donor.summary }}
            </div>

        </div>

        <ng-container *ngIf="donor.content">
            <ng-container *ngFor="let section of Object.values(donor.content)">
            <ng-container *ngIf="isNotEmpty(section.content)">
                <div class="card">
                    <div class="card-header">{{ section.title }}</div>
                    <div class="card-content" [ngClass]="{'card-content-flex': !isSmallScreen || isSmallScreen && section.type !== 'table' }">
                        <ng-container *ngIf="!section.content.length && !section.type">
                            <div *ngFor="let c of splitSection(section.content, Math.ceil(Object.keys(section.content).length / 2))" class="card-column">
                                <div *ngFor="let kvp of c | keyvalue" class="field">
                                    <div class="question">{{kvp.key}}</div>
                                    <div class="answer">{{kvp.value || '-'}}</div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="section.type === 'kvp'">
                            <div *ngFor="let c of chunk(section.content, Math.ceil(Object.keys(section.content).length / 2))" class="card-column">
                                <div class="field" *ngFor="let field of c">
                                    <div class="question">{{field.question}}</div>
                                    <div class="answer">{{field.answer || '-'}}</div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="section.type === 'table'">
                            <ng-container *ngIf="isSmallScreen">
                                <div *ngFor="let content of section.content">
                                    <h4 class="small-table-title">{{ content.title }}</h4>
                                    <table class="small-table">
                                        <tbody>
                                        <tr *ngFor="let kvp of content.content">
                                            <td>{{ kvp.question }}</td>
                                            <td>{{ kvp.answer }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!isSmallScreen">
                                <table>
                                    <thead>
                                    <tr>
                                        <th *ngIf="section.content[0].title"></th>
                                        <th *ngFor="let key of keysForTable(section.content)">{{ key }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let row of section.content">
                                        <td>{{ row.title }}</td>
                                        <td *ngFor="let key of keysForTable(section.content)">{{ utils.toHumanReadableOrUnknown(getCell(row, key)) }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="card-content card-content-flex" *ngIf="section.content.table && section.content.table.length">
                        <table>
                            <thead>
                            <tr>
                                <th *ngFor="let key of keysForTable(section.content.table)">{{ utils.toCapitalizedCase(key) }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of section.content.table">
                                <td *ngFor="let key of keysForTable(section.content.table)">{{ row[key] }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="card-content card-content-flex" *ngIf="section.table && section.table.length">
                        <table>
                            <thead>
                            <tr>
                                <th *ngFor="let key of keysForTable(section.table)">{{ utils.toCapitalizedCase(key) }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of section.table">
                                <td *ngFor="let key of keysForTable(section.table)">{{ row[key] }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        </ng-container>
    </section>
    <section class="summary-container" *ngIf="donor.version === 1">
        <div class="card general">
            <div class="card-header">General</div>
            <div class="card-content">
                <div class="iconic">
                    <div class="eye ic">
                        <div class="circle {{donor.eye_color | lowercase}}">
                            <div class="eyelash-group-up">
                                <div class="eyelash-1"></div>
                                <div class="eyelash-2"></div>
                                <div class="eyelash-3"></div>
                            </div>
                            <div class="dot"></div>
                            <div class="outer"></div>
                            <div class="eyelash-group-down">
                                <div class="eyelash-1"></div>
                                <div class="eyelash-2"></div>
                                <div class="eyelash-3"></div>
                            </div>
                        </div>
                        <span>{{donor.eye_color}} Eyes</span>
                    </div>
                    <div class="hair ic">
                        <div class="{{donor.hair_color | lowercase}}">
                            <app-insvg link="assets/woman.svg" name="woman"></app-insvg>
                        </div>
                        <span>{{donor.hair_color}} Hair</span>
                    </div>
                    <div class="height ic">
                        <div>
                            <app-insvg link="assets/ruler.svg" name="ruler"></app-insvg>
                            <app-insvg link="assets/a_woman.svg" name="a_woman"></app-insvg>
                        </div>
                        <span>{{useHeight(donor.height)}}</span>
                    </div>
                    <div class="weight ic">
                        <app-insvg link="assets/weight.svg" name="weight"></app-insvg>
                        <span>{{useWeight(donor.weight)}}</span>
                    </div>
                    <div class="age ic">
                        <app-insvg link="assets/a_tree.svg" name="tree"></app-insvg>
                        <span>{{donor.age + ' Years Old'}}</span>
                    </div>
                    <mat-divider vertical class="mobile-none"></mat-divider>
                    <div class="relation ic">
                        <app-insvg [name]="(donor.relationship_status || 'none_heart').replace(' ', '_') | lowercase" matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor.relationship_status" link="assets/relation/{{(donor.relationship_status || 'none').replace(' ', '_') | lowercase}}.svg">
                        </app-insvg>
                        <span>{{donor.relationship_status}}</span>
                    </div>
                    <mat-divider vertical class="mobile-none"></mat-divider>
                    <ul class="other-info mobile-none">
                        <li>
                            <div class="fli"><span>Ethnicity:</span><span>{{donor.ethnicity || donor.origin_ethnicity |
                                    titlecase}}</span></div>
                        </li>
                        <li>
                            <div class="fli"><span>Race:</span><span>{{donor.race || donor.origin_race}}</span></div>
                        </li>
                        <li>
                            <div class="fli"><span>Religion:</span><span>{{donor.religion ||
                                    donor.origin_religion}}</span></div>
                        </li>
                    </ul>
                </div>
                <mat-divider class="mobile-only"></mat-divider>
                <div class="iconic">
                    <div class="education ic">
                        <app-insvg [name]="(donor.education_level || 'none').toLowerCase().replace(' ', '_')" matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor.education_level" link="assets/{{(donor.education_level ? 'education/' + donor.education_level : 'none').toLowerCase().replace(' ', '_')}}.svg">
                        </app-insvg>
                        <span>{{donor.education_level || donor.origin_education_level}}</span>
                    </div>
                    <ul class="ul-education">
                        <li>
                            <div class="fli">
                                <span>High School GPA:</span>
                                <span matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor.high_school_gpa" [ngClass]="{'none': !donor.high_school_gpa}">{{donor.high_school_gpa}}</span>
                            </div>
                        </li>
                        <li>
                            <div class="fli">
                                <span>SAT:</span>
                                <span matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor.sat" [ngClass]="{'none': !donor.sat}">{{donor.sat}}</span>
                            </div>
                        </li>
                        <li>
                            <div class="fli">
                                <span>ACT:</span>
                                <span matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor.act" [ngClass]="{'none': !donor.act}">{{donor.act}}</span>
                            </div>
                        </li>
                        <li>
                            <div class="fli">
                                <span>College GPA:</span>
                                <span matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor.college_gpa" [ngClass]="{'none': !donor.college_gpa}">{{donor.college_gpa}}</span>
                            </div>
                        </li>
                    </ul>
                    <mat-divider vertical class="mobile-none"></mat-divider>
                    <div class="occupation mobile-none ic" [ngClass]="{'long': occupationLong}">
                        <app-insvg link="assets/suitcase.svg" name="suitcase"></app-insvg>
                        <span matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor.occupation && donor.occupation !== '[1]'" [ngClass]="{'none': !donor.occupation || donor.occupation === '[1]'}">{{donor.occupation}}</span>
                    </div>
                </div>
                <mat-divider class="mobile-only"></mat-divider>

                <div class="iconic mobile-only">
                    <div class="occupation ic" [ngClass]="{'long': occupationLong}">
                        <app-insvg link="assets/suitcase.svg" name="suitcase"></app-insvg>
                        <span matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor.occupation && donor.occupation !== '[1]'" [ngClass]="{'none': !donor.occupation || donor.occupation === '[1]'}">{{donor.occupation}}</span>
                    </div>
                </div>

                <mat-divider class="mobile-only"></mat-divider>
                <div class="m-other-info mobile-only">
                    <div class="fli">
                        <b>Ethnicity:</b>
                        <span>{{donor.ethnicity || donor.origin_ethnicity | titlecase}}</span>
                    </div>
                    <div class="fli">
                        <b>Race:</b>
                        <span>{{donor.race || donor.origin_race}}</span>
                    </div>
                    <div class="fli">
                        <b>Religion:</b>
                        <span>{{donor.religion || donor.origin_religion}}</span>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="info">
                    <div *ngIf="donor['why_i_want_to_become_a_donor'] && donor['why_i_want_to_become_a_donor'] !== '[1]'">
                        <p class="title">Why I Want to Become a Donor?</p>
                        <p>{{utils.toCapitalizedCase(donor['why_i_want_to_become_a_donor'])}}</p>
                    </div>
                    <div *ngIf="donor['a_message_to_intended_parents'] && donor['a_message_to_intended_parents'] !== '[1]'">
                        <p class="title">A Message to Intended Parents:</p>
                        <p>{{utils.toCapitalizedCase(donor['a_message_to_intended_parents'])}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="donor.donation_history && donor.donation_history !== '[1]' && donor.donation_history.length" class="card history">
            <div class="card-header">Donation History</div>
            <div class="card-content">
                <table>
                    <thead>
                        <tr>
                            <td *ngFor="let field of history">{{field.n}}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of donor.donation_history">
                            <td *ngFor="let field of history" matTooltip="Information was not provided by agency" [matTooltipDisabled]="row[field.s]" [ngClass]="{'none': !row[field.s]}">{{row[field.s]}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Health Container -->
        <div class="card health-container">
            <div class="card-header">Health & Medical</div>
            <div class="card-content">
                <section class="blood">
                    <app-insvg link="assets/health/injection.svg" name="syringe"></app-insvg>
                    <div class="content">
                        <div class="srow">
                            <div class="label">Blood Type:</div>
                            <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.blood_type || donor.origin_blood_type }">
                            </ng-container>
                        </div>
                        <div class="srow">
                            <div class="label">Any Transfusions?</div>
                            <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.any_transfusions }">
                            </ng-container>
                        </div>
                        <div class="srow">
                            <div class="label">Have You Ever Been Refused as a Blood Donor?</div>
                            <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.have_you_ever_been_refused_as_a_blood_donor }">
                            </ng-container>
                        </div>
                    </div>
                </section>
                <section class="habits">
                    <div class="ic" *ngFor="let icon of ['Smoker', 'Tattoos', 'Piercing', 'Alcohol', 'Recreational Drugs']">
                        <app-insvg [link]="'assets/health/' + icon.toLowerCase().replace(' ', '_') + '.svg'" [name]="icon">
                        </app-insvg>
                        <div class="label">{{icon}}</div>
                        <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor[icon.toLowerCase().replace(' ', '_')] }">
                        </ng-container>
                    </div>
                </section>
                <section class="fertility">
                    <app-insvg link="assets/health/baby.svg" name="baby_fertility"></app-insvg>
                    <div class="content">
                        <div class="srow extended srow-grid">
                            <div class="cell">
                                <div class="label">Number of Pregnancies</div>
                                <div class="value felxible egg" matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor.number_of_pregnancies !== '[1]'" [ngClass]="{'none': donor.number_of_pregnancies === '[1]'}">
                                    {{donor.number_of_pregnancies ? donor.number_of_pregnancies : 0}}
                                </div>
                            </div>
                            <div class="cell">
                                <div class="label">Number of Miscarriages</div>
                                <div class="value" matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor.number_of_pregnancies && donor.number_of_pregnancies !== '[1]'" [ngClass]="{'none': donor.number_of_pregnancies && donor.number_of_miscarriages === '[1]'}">
                                    {{donor.number_of_pregnancies ? donor.number_of_miscarriages : 0}}
                                </div>
                            </div>
                            <div class="cell">
                                <div class="label">Pregnancy Complications</div>
                                <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.number_of_pregnancies === 0 ? 'No' : donor.pregnancy_complications_if_any }">
                                </ng-container>
                            </div>
                            <div class="cell">
                                <div class="label">Pregnancy Outcome</div>
                                <span class="outcome">
                                    <ng-container
                                        *ngTemplateOutlet="probablyNone; context: { content: donor.pregnancy_outcome === '[1]' ? 'No' : donor.pregnancy_outcome }">
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="psycho">
                    <app-insvg link="assets/health/brain.svg" name="brain"></app-insvg>
                    <div class="content">
                        <div class="srow">
                            <div class="label">Mental Problems, if any:</div>
                            <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.mental_problems_if_any }">
                            </ng-container>
                        </div>
                        <div class="srow extended">
                            <div class="scol" style="white-space: pre-wrap;">
                                <div class="label">Psychiatric Counseling</div>
                                <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.psychiatric_counseling }">
                                </ng-container>
                            </div>
                            <div class="scol">
                                <div class="label">Psychiatric Hospitalization</div>
                                <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.psychiatric_hospitalization }">
                                </ng-container>
                            </div>
                            <div class="scol">
                                <div class="label">Clinical Depression</div>
                                <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.treatment_for_depression }">
                                </ng-container>
                            </div>
                            <div class="scol">
                                <div class="label">Clinically Diagnosed With ADD or ADHD?</div>
                                <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.clinical_diagnoses_with_add_or_hdhd }">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="problems">
                    <app-insvg link="assets/health/stethoscope.svg" name="stethoscope"></app-insvg>
                    <div class="content">
                        <div class="srow">
                            <div class="label">Medical Problems, if any:</div>
                            <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.medical_problems_if_any }">
                            </ng-container>
                        </div>
                        <div class="srow m-t">
                            <div class="label">Genetic Testing Completed:</div>
                            <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.genetic_testing_completed === '[1]' ? '' : donor.genetic_testing_completed }">
                            </ng-container>
                        </div>
                        <div class="srow extended srow-grid">
                            <div class="scol">
                                <div class="label">Recent Medications</div>
                                <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.recent_medications === 'No' ? 'None' : donor.recent_medications }">
                                </ng-container>
                            </div>
                            <div class="scol">
                                <div class="label">HIV/AIDS</div>
                                <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.hiv_aids }">
                                </ng-container>
                            </div>
                            <div class="scol">
                                <div class="label">STDs</div>
                                <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.st_ds }">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <!-- Health Container End -->

        <!-- Family Container -->
        <div *ngIf="relatives.length" class="family-container card">
            <div class="card-header">Family & Genetic</div>
            <div class="card-content">
                <div class="card-family" *ngFor="let relative of relatives ">
                    <div class="card-header-family">{{relative.n}}</div>
                    <div class="card-body-family">
                        <div class="container">
                            <div class="field" *ngFor="let field of relativeFields">
                                <b>{{field.n}} </b>
                                <span [class]="field.s" matTooltip="Information was not provided by agency" [matTooltipDisabled]="donor[relative.s + '_' + field.s] && donor[relative.s + '_' + field.s] !== '[1]'" [ngClass]="{'none': !donor[relative.s + '_' + field.s] || donor[relative.s + '_' + field.s] === '[1]'}">{{field.m
                                    ? field.m(donor[relative.s + '_' + field.s]) : donor[relative.s + '_' +
                                    field.s]}}</span>
                            </div>
                        </div>
                        <div class="card-body-photo"><img [src]="familysvg[relative.s]" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Family Container End -->

        <!-- Traits Container -->
        <div class="traits-container card">
            <div class="card-header">Traits & Characteristics</div>
            <div class="card-content">
                <section class="favourites">
                    <div class="ic" *ngFor="let icon of favourite">
                        <app-insvg [link]="'assets/traits/' + icon.s + '.svg'" [name]="icon.s"></app-insvg>
                        <div class="wrapper">
                            <div class="label">{{icon.n}}</div>
                            <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor[icon.s] }">
                            </ng-container>
                        </div>
                    </div>
                </section>
                <section class="personality">
                    <app-insvg link="assets/traits/personality.svg" name="personality"></app-insvg>
                    <div class="content">
                        <div class="srow">
                            <div class="label">Personality and Character</div>
                            <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.personality_and_character }">
                            </ng-container>
                        </div>
                    </div>
                </section>
                <section class="achievements">
                    <app-insvg link="assets/traits/achievements.svg" name="achievements"></app-insvg>
                    <div class="content">
                        <div class="srow">
                            <div class="label">Achievements</div>
                            <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.achievements }">
                            </ng-container>
                        </div>
                    </div>
                </section>
                <section class="goals">
                    <app-insvg link="assets/traits/goals.svg" name="goals"></app-insvg>
                    <div class="content">
                        <div class="srow">
                            <div class="label">Goals</div>
                            <ng-container *ngTemplateOutlet="probablyNone; context: { content: donor.goals }">
                            </ng-container>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <!-- Traits Container End -->
    </section>
</ng-template>

<ng-template #services>
    <div class="card" *ngIf="donor.donor_type !== 'frozen_eggs'">
        <div class="card-header card-header__cost">
            <span>
                Egg Donor Total Cost
            </span>
            <div class="total">
                <span>{{donor.total_compensation}}</span>
            </div>
        </div>
        <div class="card-content">
            <div class="disclaimer">
                <ol>
                    <li>Agency's fees and associated costs are estimates and may vary in some cases.</li>
                    <li>Total Cost includes agency compensation, egg donor fees, egg donor's screening costs, legal fees, complication insurance, escrow account service fee, and contingencies fees.</li>
                    <li>Total Cost does not include any Fertility Clinic-related expenses.</li>
                </ol>
            </div>
            <div class="table">
                <section *ngFor="let subSection of servicesSelectors.internal">
                    <header [id]="subSection.s + (subSection.inline ? '' : '__fees')" (mouseenter)="isSmallScreen ? null : onComment(subSection.s + (subSection.inline ? '' : '__fees'))" (mouseleave)="isSmallScreen ? null : onCommentClose()">
                        <span class="label">{{subSection.n}}</span>
                        <span>
                            <span *ngIf="subSection.c" class="achtung" (click)="onInfo($event, subSection.c)">i</span>
                        </span>
                        <ng-container *ngIf="!subSection.noTotal">
                            <ng-container *ngTemplateOutlet="viewRow; context: { field: subSection.s + (subSection.inline ? '' : '__fees') }">
                            </ng-container>
                        </ng-container>
                    </header>

                    <div *ngFor="let item of (servicesSelectors[subSection.s] || [])" class="row" [id]="item.s" (mouseenter)="isSmallScreen ? null : onComment(item.s)" (mouseleave)="isSmallScreen ? null : onCommentClose()">
                        <span>{{item.n}}</span>
                        <span>
                            <span *ngIf="item.c" class="achtung" (click)="onInfo($event, item.c)">i</span>
                        </span>
                        <ng-container *ngTemplateOutlet="viewRow; context: { field: item.s }"></ng-container>
                    </div>
                </section>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #viewRow let-field="field">
    <span class="cost" matTooltip="Included in agency compensation" [matTooltipDisabled]="!donor.costs[field]?.included" [innerHTML]="donor.costs[field]
			? (donor.costs[field].included
				? 'Included'
				: donor.costs[field].special_value
					? donor.costs[field].special_value
					: ((donor.costs[field].value || '0') | currency:'USD':'symbol':'1.0-0'))
      : '-'"></span>
    <div class="comment" *ngIf="donor.costs[field]?.message" [class.selected]="selectedCommentToggle === field" (click)="onComment(field)">
        <app-insvg name="dialog-bubble" link="assets/dialog-bubble.svg"></app-insvg>
    </div>
</ng-template>

<ng-template #probablyNone let-content="content">
    <div class="value" matTooltip="Information was not provided by agency" [matTooltipDisabled]="platform.SAFARI || content !== '[1]'" [title]="platform.SAFARI && 'Information was not provided by agency'" [ngClass]="{'none': content === '[1]'}">
        {{utils.toCapitalizedCase(content) || 'No'}}</div>
</ng-template>

<ng-template #expl>
    <div class="comment-card">
        <div class="cc-header">Additional information by agency:</div>
        <div class="cc-content">{{selectedComment}}</div>
    </div>
</ng-template>

<ng-template #info>
    <div class="comment-card">
        <div class="cc-content">{{selectedInfo}}</div>
        <button mat-icon-button (click)="onInfoClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #stop>
    <div class="overlay-card" [ngSwitch]="costsTutorialStep">

        <ng-container *ngSwitchCase="0">
            <div class="stop">
                Important note!
                <img src="assets/message-box.svg">
            </div>
            <p>Agency's fees and associated costs are provided by the agency associated with the egg donor.<br>Costs are estimates and may vary in some cases.</p>
            <button mat-raised-button color="accent" (click)="costsTutorialStep = 1">Continue</button>
        </ng-container>

        <ng-container *ngSwitchCase="1">
            <div class="click">
                <div class="click__animated">
                    <app-insvg class="click__animated__bubble" name="dialog-bubble" link="assets/dialog-bubble.svg">
                    </app-insvg>
                    <div class="click__animated__dashes">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <img class="click__animated__cursor" src="assets/cursor.svg">
                </div>
                <div class="click__message">You can view additional information<br>provided by the agency regarding
                    <br>each cost by simply hovering on<br>each row</div>
            </div>
            <button mat-raised-button color="accent" (click)="finishTutorial()">See Costs</button>
        </ng-container>

        <!-- preloading -->
        <div style="display: none;">
            <app-insvg name="dialog-bubble" link="assets/dialog-bubble.svg"></app-insvg>
            <img src="assets/cursor.svg">
        </div>
    </div>
</ng-template>
