<app-ivf-filters *ngIf="isSmallScreen$ | async" [withTabs]="true" [class.small]="smallFilters"></app-ivf-filters>

<div #filtersRef class="filters" [class.small]="smallFilters">
    <div *ngIf="filters?.questionnaire" class="filtersContent">
        <app-ivf-journey [buildingFamily]="filters.questionnaire.buildingFamily" [carrier]="filters.questionnaire.carrier" [eggsOwner]="filters.questionnaire.eggsOwner" [eggsOwnerAge]="filters.questionnaire.eggsOwnerAge" [inVirto]="filters.questionnaire.inVirto"
            [expandDown]="(isSmallScreen$ | async) === false" (changeValue)="updateQuestionnaire($event.id, $event.value)"></app-ivf-journey>

        <!--
        <button mat-flat-button *ngIf="(isSmallScreen$ | async) === false" color="primary" class="viewAll">View All
			Filters</button>
            -->
    </div>

    <div class="count">
        {{ dataSource.countFromSource | i18nPlural: {'=0': 'No Clinic Results', '=1': 'One Clinic Result', other: '# Clinic Results'} }}
    </div>
</div>

<!-- <app-compare-bar type="ivf_clinic" baseUrl="../../"></app-compare-bar> -->
<concierge-ftu-popup type="ivf"></concierge-ftu-popup>
<app-scroller *ngIf="(isInitialLoading$ | async) === false" id="ivf" [paddingRight]="0" [columns]="1" [dataSource]="dataSource" (changeFilters)="highlightFilters()">
    <ng-container *appScrollerItem="let item; let rowIndex = rowIndex let colIndex = colIndex">
        <app-concierge style.width="80%" type="ivf" *ngIf="
		colIndex===0 && rowIndex > 0 &&
		(
			(item.position) %5 ===1 ||
			dataSource.dataSource.value.length===1 ||
			(dataSource.dataSource.value.length===1 && rowIndex ===0 ) ||
			(dataSource.dataSource.value.length < 5 && rowIndex === 1)
		)
		"></app-concierge>

        <app-ivf-card [data]="item" [coordinates]="filters.coordinates">

        </app-ivf-card>
    </ng-container>
</app-scroller>

<ng-template #dialogTemplateRef>
    <div class="dialog">
        <h4>GoStork is Personalized to Your Journey</h4>
        <div class="subtitle">
            We’ve matched you to Fertility Clinics based on your responses to the questionnaire. We also use that information to display Clinic Results specific to Your Journey.
        </div>

        <button mat-flat-button (click)="closeDialog()" color="primary" class="close">Ok</button>
    </div>
</ng-template>
