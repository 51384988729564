import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {cloneDeep, pick} from 'lodash-es';
import {combineLatest, Subscription} from 'rxjs';
import {map, skipWhile, first, switchMapTo} from 'rxjs/operators';
import {PasswordChecksComponent} from '@/components/password-checks/password-checks.component';
import {ProviderType} from '@/config';
import {NIvfFilters} from '@/models/ivf.model';
import {AuthStoreService} from '@/services/auth.service';
import {ParentStoreService} from '@/services/parent.service';
import {UtilsService} from '@/services/utils.service';
import {IvfService} from '../ivf.service';
import {options} from '../questions';
import {AnalyticsService} from "@/services/analytics.service";

const subtitles = {
	inVirto:
		'According to the CDC, IVF Clinics’ success rates vary based on whether the IVF patient is using their own eggs, an egg donor or a surrogate. We use this information to help share the most accurate data with you.',
	credentials:
		'Your personal data will be kept 100% private and is used only to reach out to you with helpful information. We never share or sell intended parent’s data. By clicking Submit, you agree to our Terms of Use and Privacy Policy .',
	eggsOwnerAge: 'GoStork will use this age in order to show you clinic data related to this age group',
};

const additional = [
	{
		label: 'LGBTQ+',
		subtitle: 'Certain clinics specialize in working with LGBTQ+ journeys.',
		formControlName: 'lgbt',
	},
	{
		label: 'BIPOC <small>(Black, Indigenous, or Person of Color)</small>',
		subtitle: 'We prioritize clinics who practise diversity & inclusivity in their services.',
		formControlName: 'bipoc',
	},
	{
		label: 'Are you a U.S. resident?',
		subtitle: 'Certain clinics work with international residents.',
		formControlName: 'resident',
	},
	{
		label: 'Are you an HIV Patient?',
		subtitle: 'Certain clinics specialize in working with HIV patients.',
		formControlName: 'hiv',
	},
];

@Component({
	selector: 'app-questionnaire',
	templateUrl: './questionnaire.component.html',
	styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit {
	form = this.fb.group({
		name: this.fb.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
		}),
		whenToStart: this.fb.group({
			text: [null, Validators.required],
		}),
		mostImportant: this.fb.group({
			text: [null, Validators.required],
		}),
		location: this.fb.group({
			text: [null, Validators.required],
			country: [null],
			state: [null],
			city: [null],
		}),
		buildingFamily: this.fb.group({
			text: [null, Validators.required],
		}),
		carrier: this.fb.group({
			text: [null, Validators.required],
		}),
		gestationalCarrier: this.fb.group({
			text: [null, Validators.required],
		}),
		eggsOwner: this.fb.group({
			text: [null, Validators.required],
		}),
		eggsOwnerAge: this.fb.group({
			text: [null, Validators.required],
		}),
		spermDonor: this.fb.group({
			text: [null, Validators.required],
		}),
		inVirto: this.fb.group({
			text: [null, Validators.required],
		}),
		relationshipStatus: this.fb.group({
			text: [null, Validators.required],
			firstName: [null, (control) => (this.showPartnerBox ? Validators.required(control) : null)],
			lastName: [null, (control) => (this.showPartnerBox ? Validators.required(control) : null)],
			age: [null, (control) => (this.showPartnerBox ? Validators.required(control) : null)],
		}),
		additional: this.fb.group({
			lgbt: [null, Validators.required],
			bipoc: [null, Validators.required],
			resident: [null, Validators.required],
			hiv: [null, Validators.required],
		}),
		credentials: this.fb.group({
			email: [null, [Validators.required, UtilsService.EmailValidator]],
			dob: [null, Validators.required],
			phone: [null, Validators.required],
			password: [null, [Validators.required, PasswordChecksComponent.Validator]],
		}),
		verification: this.fb.group({
			text: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
		}),
		review: this.fb.group({}),
		loader: this.fb.group({}),
	});
	options = cloneDeep(options);
	subtitles = subtitles;
	additional = additional;

	allQuestions = Object.keys(this.form.controls);

	partial = false;

	get questions() {
		const questionsToIgnore = [];

		const buildingFamily = this.form.get('buildingFamily.text').value as NIvfFilters.BuildingFamily;
		const eggsOwner = this.form.get('eggsOwner.text').value as NIvfFilters.EggsOwner;
		const carrier = this.form.get('carrier.text').value as NIvfFilters.PregnancyCarreir;

		if (buildingFamily === NIvfFilters.BuildingFamily.Freezing) {
			questionsToIgnore.push('eggsOwner', 'carrier', 'spermDonor', 'inVirto');
		}

		if (
			buildingFamily !== NIvfFilters.BuildingFamily.Freezing &&
			(eggsOwner === NIvfFilters.EggsOwner.DonorEggs || eggsOwner === NIvfFilters.EggsOwner.DobnorEmbryo)
		) {
			questionsToIgnore.push('eggsOwnerAge');
		}

		if (carrier !== NIvfFilters.PregnancyCarreir.GestationalCarrier) {
			questionsToIgnore.push('gestationalCarrier');
		}

		if (carrier === NIvfFilters.PregnancyCarreir.Reciprocal) {
			this.options.eggsOwner = options.eggsOwner.slice(0, 2);
		} else {
			this.options.eggsOwner = options.eggsOwner;
		}

		if (this.partial) {
			questionsToIgnore.push('name', 'relationshipStatus', 'credentials', 'verification');
		}

		return this.allQuestions.filter((item) => !questionsToIgnore.includes(item));
	}
	currentQuestion = this.questions[0];
	visited = new Set<string>([this.currentQuestion]);

	get currentStep() {
		return this.form.controls[this.currentQuestion];
	}

	get isFirstStep() {
		return this.currentQuestion === this.questions[0];
	}

	get name() {
		return this.form.get('name.firstName').value;
	}

	get email() {
		return this.form.get('credentials.email').value;
	}

	get phone() {
		return this.form.get('credentials.phone').value;
	}

	get eggsOwnerName() {
		const value = this.form.get('eggsOwner.text').value;
		const buildingFamily = this.form.get('buildingFamily.text').value as NIvfFilters.BuildingFamily;

		return buildingFamily === NIvfFilters.BuildingFamily.Freezing ||
			value === NIvfFilters.EggsOwner.DonorEggs ||
			value === NIvfFilters.EggsOwner.MyEggs
			? 'eggs'
			: 'embryos';
	}

	get showNext() {
		return ['name', 'location', 'eggsOwnerAge', 'relationshipStatus', 'additional', 'credentials', 'verification', 'review'].includes(
			this.currentQuestion,
		);
	}

	get showBack() {
		return !['verification', 'review', 'loader'].includes(this.currentQuestion);
	}

	get showPartnerBox() {
		const marital = this.form?.get('relationshipStatus.text')?.value;
		return marital && marital !== 'single';
	}

	get journeyText() {
		return this.ivfService.buildJourneyText(
			Object.fromEntries(
				Object.entries(this.form.value)
					.map(([key, value]: [string, Record<string, unknown>]) => ('text' in value ? [key, value.text] : null))
					.filter(Boolean),
			),
		);
	}

	loading = false;
	error = '';

	routerSubscription: Subscription;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private ivfService: IvfService,
		private authService: AuthStoreService,
		private parentService: ParentStoreService,
		private route: ActivatedRoute,
		private analytics: AnalyticsService
	) {}

	refreshValidity(control: AbstractControl = this.currentStep) {
		// for some reason updateValueAndValidity does not update children
		if (control instanceof FormGroup) {
			Object.values(control.controls).forEach((item) => this.refreshValidity(item));
		} else {
			control.updateValueAndValidity({emitEvent: false});
		}
	}

	fillLocationDetails(details: {country: string; state: string; city: string}) {
		this.form.get('location.country').setValue(details.country);
		this.form.get('location.state').setValue(details.state);
		this.form.get('location.city').setValue(details.city);
	}

	successRedirect() {
		this.router.navigate(['/ivf'], {
			queryParams: {
				firstTime: true,
			},
		});
	}

	changeJourney(id: string, value: any) {
		this.form.get(id + '.text').setValue(value);
		this.ivfService.updateLocalFilters({
			...this.ivfService.state.filters,
			questionnaire: {
				...(this.ivfService.state.filters?.questionnaire || {}),
				[id]: value,
			},
		});
	}

	verifyUser() {
		this.loading = true;

		this.authService
			.verifyCode(this.email, this.form.get('verification.text').value)
			.pipe(switchMapTo(this.authService.update()), switchMapTo(this.parentService.update()))
			.subscribe({
				next: () => {
					this.loading = false;
					this.analytics.emit('ivf-questionnaire', 'register', 'activated');

					this.onStepChange(this.questions.indexOf(this.currentQuestion) + 1, true);
				},
				error: (error) => {
					this.error = '';
					this.loading = false;
					if (error instanceof HttpErrorResponse) {
						if (error.status === 403 || error.status === 400) {
							this.error = 'Code is not valid. Try again.';
						} else {
							throw error;
						}
					} else {
						throw error;
					}
				},
			});
	}

	createUser() {
		this.loading = true;

		const get = (field: string) => this.form.get(field).value;

		const services: NIvfFilters.IvfService[] = [];

		const sorting = get('mostImportant.text') as NIvfFilters.IvfSort | -1;

		if (get('buildingFamily.text') === NIvfFilters.BuildingFamily.Freezing) {
			services.push(NIvfFilters.IvfService.EggFreezing);
		}
		if (get('carrier.text') === NIvfFilters.PregnancyCarreir.GestationalCarrier) {
			services.push(NIvfFilters.IvfService.Surrogacy);
		}
		if (get('eggsOwner.text') === NIvfFilters.EggsOwner.DonorEggs) {
			services.push(NIvfFilters.IvfService.EggDonation);
		} else if (get('eggsOwner.text') === NIvfFilters.EggsOwner.DobnorEmbryo) {
			services.push(NIvfFilters.IvfService.EmbryoDonation);
		}

		if (get('carrier.text') === NIvfFilters.PregnancyCarreir.Reciprocal) {
			services.push(NIvfFilters.IvfService.ReciprocalIvf);
		}

		if (get('spermDonor.text') === NIvfFilters.SpermDonor.Yes) {
			services.push(NIvfFilters.IvfService.SpermDonation);
		}

		if (get('additional.hiv')) {
			services.push(NIvfFilters.IvfService.HivPatients);
		}

		// todo: services are not used to allow filtering through all clinics
		// todo: also, we do not have UI for changing some options
		console.log(services);

		const form = {
			product: ProviderType.IVF_CLINIC,
			password: get('credentials.password'),
			personal: {
				first_name: get('name.firstName'),
				last_name: get('name.lastName'),
				birthday: get('credentials.dob')?.toISOString().split('T')[0],
				email: get('credentials.email'),
				phone: get('credentials.phone'),

				relation: get('relationshipStatus.text'),
				identify: get('additional.lgbt') ? 'lgbt' : 'heterosexual',

				bipoc: get('additional.bipoc'),
				us_resident: get('additional.resident'),
				hiv: get('additional.hiv'),
			},
			filters: {
				sort: sorting === -1 ? null : sorting,
				order: sorting === NIvfFilters.IvfSort.JourneyCost ? 'ASC' : 'DESC',
				search_by: '',
				services: [],
				country: get('location.country'),
				state: get('location.state'),
				city: get('location.city'),

				whenToStart: get('whenToStart.text'),

				buildingFamily: get('buildingFamily.text'),
				eggsOwner: get('eggsOwner.text'),
				eggsOwnerAge: get('eggsOwnerAge.text'),
				carrier: get('carrier.text'),
				gestationalCarrier: get('gestationalCarrier.text'),
				inVirto: get('inVirto.text'),
				spermDonor: get('spermDonor.text'),
				additional: get('additional'),
				insurance: null,
			},
			partner: {
				first_name: get('relationshipStatus.firstName'),
				last_name: get('relationshipStatus.lastName'),
				age: get('relationshipStatus.age'),
			},
		};

		if (this.partial) {
			this.parentService
				.updateUserQuestionnaire(
					{
						...form.filters,
						...pick(form.personal, 'identify', 'bipoc', 'us_resident', 'hiv'),
					},
					ProviderType.IVF_CLINIC,
				)
				.pipe(switchMapTo(this.parentService.update()))
				.subscribe(() => {
					this.loading = false;
					this.onStepChange(this.questions.indexOf(this.currentQuestion) + 1, true);
				});
		} else {
			this.ivfService.createProfile(form).subscribe({
				next: (res) => {
					this.loading = false;
					if (res.status === 200) {
						this.authService
							.update()
							.pipe(switchMapTo(this.parentService.update()))
							.subscribe(() => this.onStepChange(this.questions.indexOf(this.currentQuestion) + 2, true));
					} else {
						this.onStepChange(this.questions.indexOf(this.currentQuestion) + 1, true);
					}
				},
				error: (error: HttpErrorResponse) => {
					switch (error.status) {
						case 401:
							this.loading = false;
							this.form.get('credentials.password').setErrors({exists: true});
							break;
						case 403:
							this.loading = false;
							this.onStepChange(this.questions.indexOf(this.currentQuestion) + 1, true);
							break;
						default:
							throw error;
					}
				},
			});
		}
	}

	onStepChange(index = this.questions.indexOf(this.currentQuestion) + 1, force = false) {
		switch (this.currentQuestion) {
			case 'credentials':
				if (force) {
					break;
				}

				if (this.currentStep.valid) {
					this.createUser();
				}
				return;
			case 'verification':
				if (force) {
					break;
				}

				if (this.currentStep.valid) {
					this.verifyUser();
				}
				return;
			case 'inVirto':
				if (force || !this.partial) {
					break;
				}

				if (this.currentStep.valid) {
					this.createUser();
				}
				return;
			case 'eggsOwner':
				if ([NIvfFilters.EggsOwner.DonorEggs, NIvfFilters.EggsOwner.DobnorEmbryo].includes(this.form.get('eggsOwner.text').value)) {
					this.form.get('eggsOwnerAge.text').setValue(null);
				}
				break;

			case 'review':
				if (this.partial) {
					if (force) {
						break;
					}
					this.createUser();
					return;
				}
		}

		this.currentQuestion = this.questions[index];
		this.questions.slice(index).forEach((item) => this.visited.delete(item));
		this.visited.add(this.currentQuestion);
		this.router.navigate(['../', index + 1], {relativeTo: this.route});

		if (this.currentQuestion === 'loader') {
			setTimeout(() => this.successRedirect(), 2000);
		}
	}

	goToPreviousStep() {
		this.onStepChange(this.questions.indexOf(this.currentQuestion) - 1, true);
	}

	locationDoesntMatter() {
		['location.country', 'location.state', 'location.city'].forEach((key) => this.form.get(key).setValue(null));
		this.onStepChange();
	}

	ngOnInit() {
		this.routerSubscription = this.route.params
			.pipe(map(({step}) => +step - 1))
			.subscribe(
				(step) =>
					step !== this.questions.indexOf(this.currentQuestion) &&
					this.onStepChange(this.visited.has(this.questions[step]) ? step : this.questions.indexOf(this.currentQuestion)),
			);

		combineLatest([this.authService.loading, this.parentService.loading])
			.pipe(
				skipWhile(([loading1, loading2]) => loading1 || loading2),
				first(),
			)
			.subscribe(() => {
				if (!this.authService.state.value) {
					return;
				}
				const questionnaire = this.parentService.getUserQuestionnaire();
				if (questionnaire[ProviderType.IVF_CLINIC]) {
					return this.router.navigate(['/ivf']);
				} else {
					this.partial = true;
					this.form.get('name.firstName').setValue(this.authService.value.name.split(' ')[0]);
					this.currentQuestion = this.questions[0];
					this.visited = new Set<string>([this.currentQuestion]);
				}
			});
	}
}
