import {Component, Input} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

@Component({
	selector: 'app-password-checks',
	templateUrl: './password-checks.component.html',
	styleUrls: ['./password-checks.component.scss'],
})
export class PasswordChecksComponent {
	errors = new Set<string>();

	@Input()
	public set value(value: string) {
		this._value = value;
		this.errors.clear();
		const errors = PasswordChecksComponent.validatorFn(value);
		if (errors) {
			Object.keys(errors).forEach((error) => this.errors.add(error));
		}
	}
	public get value(): string {
		return this._value;
	}
	private _value: string;

	constructor() {}

	static Validator: ValidatorFn = (control: AbstractControl) => PasswordChecksComponent.validatorFn(control.value);

	private static validatorFn = (value: string) => ({
		...(value?.length > 8 ? null : {minlength: {value}}),
		...(value && /[A-Z]+/.test(value) ? null : {capital: {value}}),
		...(value && /[a-z]+/.test(value) ? null : {lower: {value}}),
		...(value && /[0-9]+/.test(value) ? null : {number: {value}}),
	});
}
