import { NIvfFilters } from '@/models/ivf.model';

const DEFAULT = -1;

export const options = {
	buildingFamily: [
		{
			label: 'Build My Family Through Pregnancy (Live Birth)',
			value: NIvfFilters.BuildingFamily.Pregnancy,
		},
		{
			label: 'Freeze My Eggs',
			value: NIvfFilters.BuildingFamily.Freezing,
		},
	],
	eggsOwner: [
		{
			label: "My or My Partner's Eggs",
			value: NIvfFilters.EggsOwner.MyEggs,
		},
		{
			label: "My or My Partner's Embryos",
			value: NIvfFilters.EggsOwner.MyEmbryo,
		},
		{
			label: 'Donor Eggs',
			value: NIvfFilters.EggsOwner.DonorEggs,
		},
		{
			label: 'Donor Embryos',
			value: NIvfFilters.EggsOwner.DobnorEmbryo,
		},
	],
	carrier: [
		{
			label: 'Myself or My Partner',
			value: NIvfFilters.PregnancyCarreir.Self,
		},
		{
			label: 'Gestational Carrier',
			value: NIvfFilters.PregnancyCarreir.GestationalCarrier,
		},
		{
			label: 'Reciprocal IVF',
			value: NIvfFilters.PregnancyCarreir.Reciprocal,
		},
	],
	inVirto: [
		{label: 'Yes', value: NIvfFilters.InVirto.Yes},
		{label: 'No', value: NIvfFilters.InVirto.No},
		{label: 'Not Sure', value: NIvfFilters.InVirto.NotSure},
	],
	mostImportant: [
		{
			label: 'A Clinic’s Experience',
			value: NIvfFilters.IvfSort.BabiesBorn,
		},
		{
			label: 'A Clinic’s Success Rates',
			value: NIvfFilters.IvfSort.SuccessOwnEggs,
		},
		{
			label: 'The Lowest Cost',
			value: NIvfFilters.IvfSort.JourneyCost,
		},
		{
			label: 'No Preference',
			value: DEFAULT,
		},
	],
	relationshipStatus: [
		{label: 'Single', value: 'single', icon: 'assets/relation/single.svg'},
		{label: 'Committed Relationship', value: 'committed_relationship', icon: 'assets/relation/committed_relationship.svg'},
		{label: 'Married', value: 'married', icon: 'assets/relation/married.svg'},
	],
	whenToStart: [
		{label: 'ASAP!', value: 'asap'},
		{label: '1 to 3 Months', value: '1-3'},
		{label: '3 to 6 Months', value: '3-6'},
		{label: 'Just Researching', value: 'researching'},
	],
	spermDonor: [
		{label: 'Yes', value: NIvfFilters.SpermDonor.Yes},
		{label: 'No', value: NIvfFilters.SpermDonor.No},
		{label: 'Not Sure', value: NIvfFilters.SpermDonor.NotSure},
	],
	gestationalCarrier: [
		{label: 'Yes', value: NIvfFilters.Gestational.Yes},
		{label: 'No', value: NIvfFilters.Gestational.No},
		{label: 'Not Sure', value: NIvfFilters.Gestational.NotSure},
	],
};
