import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {ProviderType} from '@/config';
import {AuthGuard} from '@/services/auth.guard';

import {ProviderProfileComponent} from '../provider-profile/provider-profile.component';
import {IvfComponent} from './ivf.component';
import {QuestionnaireComponent} from './questionnaire/questionnaire.component';

@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: '',
				canActivate: [AuthGuard],
				data: {
					roles: ['parent', 'admin', 'provider'],
					fallbackUrl: ['ivf/questionnaire/1'],
				},
				component: IvfComponent,
				pathMatch: 'full',
			},
			{
				path: 'questionnaire',
				redirectTo: 'questionnaire/1',
			},
			{
				path: 'questionnaire/:step',
				component: QuestionnaireComponent,
			},
			{
				path: 'profile/edit/:providerId/:id/:slug',
				component: ProviderProfileComponent,
				data: {
					type: ProviderType.IVF_CLINIC,
				},
			},
			{
				path: 'profile/:providerId/:id/:slug',
				component: ProviderProfileComponent,
				data: {
					type: ProviderType.IVF_CLINIC,
				},
			},
		]),
	],
	exports: [RouterModule],
})
export class IvfRoutingModule {}
