import {BreakpointObserver} from '@angular/cdk/layout';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {Platform} from '@angular/cdk/platform';
import {DomPortalOutlet, TemplatePortal} from '@angular/cdk/portal';
import {CurrencyPipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {
	AfterViewInit,
	ApplicationRef,
	Component,
	ComponentFactoryResolver,
	ElementRef,
	HostListener,
	Injector,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabGroup} from '@angular/material/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {chunk, orderBy} from 'lodash-es';
import {Observable, Subscription} from 'rxjs';
import {first} from 'rxjs/operators';

import {LocationUpdateService} from '@/components/calendar/calendar-list/LocationUpdateService';
import {nanoid} from '@/helpers/nanoid';
import {AuthStoreService} from '@/services/auth.service';
import {CompaniesStoreService, ICompany} from '@/services/company.service';
import {ProvidersStoreService} from '@/services/providers.service';
import {ISurrogateExtended, SurrogatesStoreService} from '@/services/surrogates.service';
import {ProviderType} from '../../config';
import {AnalyticsService} from '../../services/analytics.service';
import {ChatService} from '../../services/chat.service';
import {ContactAgencyService} from '../../services/contact-agency.service';
import {ParentStoreService} from '../../services/parent.service';
import {UtilsService} from '../../services/utils.service';

const LSCostsTutorialKey = '__costs__tutorial--surrogates';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
	providers: [ContactAgencyService, CurrencyPipe],
	encapsulation: ViewEncapsulation.None,
})
export class SurrogateProfileComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('carousel', {static: false})
	carousel: ElementRef<HTMLUListElement>;
	@ViewChild('video')
	videoRef: ElementRef<HTMLVideoElement>;

	@ViewChild('overlay', {static: false})
	overlayTemplate: TemplateRef<any>;
	@ViewChild('stop')
	stopRef: TemplateRef<any>;

	@ViewChild('info')
	infoTemplate: TemplateRef<any>;

	@ViewChild('actions') actionsTemplate: TemplateRef<any>;

	@ViewChild('services', {static: false}) services: TemplateRef<any>;

	@ViewChild(MatTabGroup)
	tabGroup: MatTabGroup;

	@ViewChild('expl')
	commentTemplate: TemplateRef<null>;

	costsTutorialStep = 0;

	surrogate: ISurrogateExtended;
	images: string[] = [];
	agency$: Subscription;
	agency: ICompany;
	_subscription = new Subscription();
	checked$: Observable<boolean>;
	isInFavourite$: Observable<boolean>;

	loadingPDF = false;
	creatingDeal = false;

	overlayImage: number = null;
	overlayRef: OverlayRef;

	tabs: Array<{template?: TemplateRef<any>; title: string; id: string}> = [];

	hasChat$: Observable<string>;
	comparing$: Observable<number[]>;

	occupationLong = false;
	isSmallScreen: boolean;

	selectedIndex = 0;
	tabsOffset = [];

	selectedCommentToggle = '';
	selectedComment = '';
	selectedInfo = '';

	showContactAgencyAsStatic = false;

	interested$: Observable<boolean>;

	commentOverlayRef: OverlayRef;
	totalCost: string;

	constructor(
		private authStore: AuthStoreService,
		private store: SurrogatesStoreService,
		private parentService: ParentStoreService,
		private companyService: CompaniesStoreService,
		private providerService: ProvidersStoreService,
		private route: ActivatedRoute,
		private overlay: Overlay,
		private snack: MatSnackBar,
		private _viewContainerRef: ViewContainerRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
		private router: Router,
		private analytics: AnalyticsService,
		private breakpointObserver: BreakpointObserver,
		public platform: Platform,
		public utils: UtilsService,
		private chatService: ChatService,
		private contactAgencyService: ContactAgencyService,
		private currencyPipe: CurrencyPipe,
		private locationUpdateService: LocationUpdateService,
	) {
		this.analytics.setDimension('Surrogate Profile', 'Surrogate');
	}

	@HostListener('window:scroll')
	onWindowScroll() {
		let scrolledIndex = 0;
		for (let i = 0; i < this.tabsOffset.length; i++) {
			if (this.tabsOffset[i] < window.scrollY) {
				scrolledIndex = i;
			}
		}
		if (this.selectedIndex !== scrolledIndex) {
			this.selectedIndex = scrolledIndex;
		}

		this.showContactAgencyAsStatic = this.isSmallScreen && window.scrollY > 360;
	}

	onChange(event: 'interested' | 'print' | 'share' | 'stared' | 'checked' | 'premium', _event?: MouseEvent) {
		if (event === 'premium') {
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Surrogate Profile',
				label: 'CTA - Contact Agency',
			});
			this.hasChat$.pipe(first()).subscribe((chatId) =>
				chatId
					? this.router.navigate(['/', 'chat', chatId])
					: this.contactAgencyService.openDialog(ProviderType.SURROGATES_AGENCY, {
							surrogateId: this.surrogate.id,
							companyId: this.surrogate['Agency.provider_mapping.provider_id'],
							agencyName: this.surrogate['Agency.provider_mapping.provider.company_name'],
					  }),
			);
			this.analytics.emit('surrogates', 'create-deal-profile', this.surrogate.id.toString());
		} else if (event === 'stared') {
			this.parentService.toggleInFavourites(this.surrogate.id, ProviderType.SURROGATES_AGENCY);
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Surrogate Profile',
				label: 'CTA - Like',
			});
		} else if (event === 'interested') {
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Surrogate Profile',
				label: 'CTA - Contact Agency',
			});
			this.hasChat$.pipe(first()).subscribe((chatId) =>
				chatId
					? this.router.navigate(['/', 'chat', chatId])
					: this.contactAgencyService.openDialog(ProviderType.SURROGATES_AGENCY, {
							surrogateId: this.surrogate.id,
							companyId: this.surrogate['Agency.provider_mapping.provider_id'],
							agencyName: this.surrogate['Agency.provider_mapping.provider.company_name'],
					  }),
			);
			this.analytics.emit('surrogates', 'create-deal-profile', this.surrogate.id.toString());
		} else if (event === 'share') {
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Surrogate Profile',
				label: 'CTA - Share',
			});
			if (_event && _event.ctrlKey) {
				this.snack
					.open(this.surrogate.origin, 'GO', {duration: 3278, horizontalPosition: 'left'})
					.onAction()
					.subscribe(() => window.open(this.surrogate.origin, '_blank'));
			} else {
				const input = document.createElement('input');
				input.value = `${location.host}/surrogates/${this.surrogate.id}`;
				document.body.appendChild(input);
				input.select();
				document.execCommand('copy');
				document.body.removeChild(input);
				this.snack.open('The link to the profile has been copied to the clipboard.', 'Close', {
					duration: 3278,
					horizontalPosition: 'left',
				});
			}
		} else if (event === 'print') {
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Surrogate Profile',
				label: 'CTA - Save PDF',
			});
			if (this.surrogate.pdf) {
				const url = `/images/${this.surrogate.pdf}`;
				const blocked = window.open(url, this.surrogate.id.toString()) === null;
				if (blocked) {
					const link = document.createElement('a');
					link.href = url;
					link.target = '_blank';
					link.download = `GoStork - Donor #${this.surrogate.id}.pdf`;
					link.click();
				}
				return;
			}
			this.loadingPDF = true;
		} else if (event === 'checked') {
			this.analytics.push({
				token: nanoid(12),
				category: 'User Area',
				action: 'Surrogate Profile',
				label: 'CTA - Add to Compare',
			});
			this.parentService.toggleInCompare(this.surrogate.id, ProviderType.SURROGATES_AGENCY);
		}
	}

	useHeight(n: number | string) {
		if (typeof n === 'string') n = Number(n);
		return this.utils.useHeight(n, this.parentService.getUnits());
	}

	useWeight(n: number | string) {
		if (typeof n === 'string') n = Number(n);
		return this.utils.useWeight(n, this.parentService.getUnits());
	}

	isNotEmpty(value: Record<string, unknown>) {
		return Object.values(value || {}).some((item) => item);
	}

	onScroll(direction: 'forward' | 'backward') {
		this.carousel.nativeElement.scrollBy({
			left: (document.documentElement.clientWidth / 2.2) * (direction === 'forward' ? 1 : -1),
			behavior: 'smooth',
		});
	}

	onComment(selected: string) {
		this.selectedComment = this.surrogate.costs[selected]?.message;
		if (!this.selectedComment) return;
		this.selectedCommentToggle = selected;
		const anchor = document.getElementById(this.selectedCommentToggle);
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(anchor)
			.withPositions([
				{
					overlayX: 'center',
					overlayY: 'top',
					originX: 'end',
					originY: 'bottom',
				},
				{
					overlayX: 'center',
					overlayY: 'bottom',
					originX: 'end',
					originY: 'top',
				},
			]);
		const scrollStrategy = this.overlay.scrollStrategies.reposition({autoClose: true});
		this.commentOverlayRef = this.overlay.create({
			positionStrategy,
			scrollStrategy,
			hasBackdrop: this.platform.IOS || this.platform.ANDROID,
		});
		this.commentOverlayRef.backdropClick().subscribe(() => this.onCommentClose());
		const portal = new TemplatePortal(this.commentTemplate, this._viewContainerRef);
		this.commentOverlayRef.attach(portal);
	}

	onCommentClose() {
		this.commentOverlayRef?.dispose();
		this.selectedCommentToggle = '';
		this.selectedComment = '';
	}

	onInfoClose() {
		this.overlayRef?.dispose();
		this.selectedInfo = '';
	}

	onInfo(event: Event, message: string) {
		event.stopPropagation();
		this.selectedInfo = message;
		const positionStrategy = this.isSmallScreen
			? this.overlay.position().global().centerHorizontally().centerVertically()
			: this.overlay
					.position()
					.flexibleConnectedTo(event.target as HTMLElement)
					.withPositions([
						{
							originX: 'end',
							originY: 'center',
							overlayX: 'start',
							overlayY: 'center',
						},
					])
					.withViewportMargin(60)
					.withDefaultOffsetX(30);
		this.overlayRef = this.overlay.create({positionStrategy, hasBackdrop: true});
		this.overlayRef.backdropClick().subscribe(() => this.onInfoClose());
		const portal = new TemplatePortal(this.infoTemplate, this._viewContainerRef);
		this.overlayRef.attach(portal);
	}

	trackBy(item: ISurrogateExtended) {
		if (!item) {
			return null;
		}
		return item.id;
	}

	onImageOpen(index: number) {
		this.overlayImage = index;
		const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
		this.overlayRef = this.overlay.create({
			hasBackdrop: true,
			disposeOnNavigation: true,
			positionStrategy,
		});
		const dialog = new TemplatePortal(this.overlayTemplate, this._viewContainerRef);
		this.overlayRef.backdropClick().subscribe(() => this.overlayRef.dispose());
		this.overlayRef.keydownEvents().subscribe(({key}) => {
			if (key === 'ArrowLeft') {
				this.onImageChange('backward');
			} else if (key === 'ArrowRight') {
				this.onImageChange('forward');
			}
		});
		this.overlayRef.attach(dialog);
	}

	onImageChange(direction: 'forward' | 'backward') {
		if (direction === 'forward' && this.overlayImage === this.images.length - 1) {
			this.overlayImage = this.surrogate.video ? -1 : 0;
		} else if (direction === 'backward' && this.overlayImage < 1) {
			this.overlayImage = this.surrogate.video && this.overlayImage === 0 ? -1 : this.images.length - 1;
		} else {
			this.overlayImage = this.overlayImage + (direction === 'forward' ? 1 : -1);
		}
	}

	onTabChange(index: number) {
		/*if (index === 1) {
			this.startTutorial();
		}*/
		if (this.selectedIndex === index) {
			return;
		}
		this.selectedIndex = index;
		const element = document.getElementById(this.tabs[index].id);
		if (element) {
			window.scrollTo({top: element.offsetTop - 140, behavior: 'smooth'});
		}
	}

	remoteFromCompare(id: number) {
		this.parentService.removeFromCompare(id, ProviderType.SURROGATES_AGENCY);
	}

	startTutorial() {
		if (localStorage.getItem(LSCostsTutorialKey) === 'true' || this.overlayRef) {
			return;
		}
		const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
		this.overlayRef = this.overlay.create({
			hasBackdrop: true,
			disposeOnNavigation: true,
			positionStrategy,
		});
		const dialog = new TemplatePortal(this.stopRef, this._viewContainerRef);
		this.overlayRef.attach(dialog);
	}

	finishTutorial() {
		this.overlayRef.dispose();
		this.overlayRef = null;
		localStorage.setItem(LSCostsTutorialKey, 'true');
	}

	ngOnInit() {
		this.locationUpdateService.sendData(true);
		this.route.params.pipe(first()).subscribe((params) => {
			this.store
				.fetchSurrogateById(params.id, 'long')
				.pipe(first())
				.subscribe({
					next: ([data]) => {
						this.store.setWatched(data.id);
						this.interested$ = this.parentService.isInterestedIn(data.id);
						this.hasChat$ = this.chatService.surrogateHasChat$(data.id);
						const providerId = data['Agency.provider_mapping.provider.id'];
						this.companyService
							.fetchCompanyById(providerId, 'long')
							.pipe(first())
							.subscribe(([result]) => {
								this.providerService.getFullCosts(providerId).subscribe((res) => {
									this.agency = {
										...this.agency,
										costs: res[ProviderType.SURROGACY_AGENCY],
									};
								});
								this.agency = result;
							});
						this.agency$ = this.store.getAgencyById(data.agency_id).subscribe((agencies) => {
							const r: Record<string, any> = this.utils.deepCopy(agencies[0]);
							for (const key of Object.keys(data)) {
								if (key.endsWith('At')) {
									continue;
								}
								if (!data[key] && r[key]) continue;
								r[key] = this.utils.toHumanReadableOrUnknown(data[key]);
							}
							if (data.deletedAt) {
								r.deletedAt = new Date(data.deletedAt);
							}
							this.surrogate = r as ISurrogateExtended;
						});
						this.images = data.images;
						this.checked$ = this.parentService.isInCompare(data.id, ProviderType.SURROGATES_AGENCY);
						this.isInFavourite$ = this.parentService.isInFavourites(data.id, ProviderType.SURROGATES_AGENCY);
						setTimeout(() => {
							if (this.videoRef) {
								this.videoRef.nativeElement.muted = true;
							}
							if (this.isSmallScreen) return;
							const header = document.querySelector('mat-tab-header');
							if (!header) return;
							const portalHost = new DomPortalOutlet(header, this.componentFactoryResolver, this.appRef, this.injector);
							const portal = new TemplatePortal(this.actionsTemplate, this._viewContainerRef);
							portalHost.attach(portal);
						}, 100);
					},
					error: (error: HttpErrorResponse) =>
						error.status === 404 ? console.warn(`#${params.id} not found`) : console.error(error),
				});
		});
		this.comparing$ = this.parentService.getCompare(ProviderType.SURROGATES_AGENCY);
		window['get_origin'] = () =>
			console.log(
				`GSID :  ${this.surrogate.id}\nID   :  ${this.surrogate.origin_id}\nDID  :  ${this.surrogate.theirsId}\nLink :  ${this.surrogate.origin}`,
			);
		this.router.navigate(['./'], {fragment: '', relativeTo: this.route, skipLocationChange: true});
	}

	ngAfterViewInit() {
		Promise.resolve().then(() => {
			this.tabs = [
				{title: 'About', id: 'about'},
				{template: this.services, title: 'Costs', id: 'costs'},
			];
			this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 812.99px)');
		});
		const updateHeight = () =>
			setTimeout(() => {
				try {
					this.tabsOffset = this.tabs.map((item) => document.getElementById(item.id).offsetTop - 140);
				} catch (e) {
					updateHeight();
				}
			}, 1000);
		updateHeight();
	}

	ngOnDestroy() {
		this.locationUpdateService.sendData(null);
		delete window['get_origin'];
		this._subscription.unsubscribe();
		this.agency$?.unsubscribe();
	}

	public readonly Object = Object;
	public readonly Math = Math;
	public readonly chunk = chunk;

	keysForTable(section: Record<string, any>[]) {
		const allKeys = [];
		section.map((row) => {
			Object.keys(row?.content || row).forEach((k) => {
				if (!allKeys.includes(k)) {
					allKeys.push(k);
				}
			});
		});

		return orderBy(
			allKeys,
			(k) => {
				const idx = ['child', 'year of delivery'].indexOf(k.toLowerCase());
				if (idx === -1) {
					return 999999;
				}
				return idx;
			},
			'asc',
		);
	}
	public reportCost = (cost: string) => {
		this.totalCost = cost;
	};

	splitSection(section: Record<string, any>, chunks: number): Record<string, any>[] {
		const sectionKeys = Object.keys(section).filter((k) => k !== 'table');
		const chunkKeys = chunk(sectionKeys, chunks);
		const objects = chunkKeys.map((c) => {
			const obj = {};
			c.forEach((key) => (obj[key] = section[key]));
			return obj;
		});
		return objects;
	}

	orderPregnancyResults(content: any) {
		return orderBy(content, (c) => Number(c?.content['Year of delivery'] || 0), 'asc');
	}
}
