import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChatComponent } from './chat.component';

import { CalendarModule } from '../../components/calendar/calendar.module';
import { RatingModule } from '../../components/rating/rating.module';
import { InsvgModule } from '../../components/insvg/insvg.module';
import { FormFieldModule } from '../../components/form-field/form-field.module';

import { PipesModule } from '../../pipes/pipes.module';

import { MaterialModule } from '../../material.module';

@NgModule({
	declarations: [ChatComponent],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		CalendarModule,
		RatingModule,
		InsvgModule,
		PipesModule,
		FormFieldModule
	]
})
export class ChatModule { }
