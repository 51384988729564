<div class="check" (click)="onChange()">
	<svg style="height: 0; pointer-events: none; position: absolute;">
		<linearGradient id="linear-gradient" x1="-0.085" y1="-0.059" x2="0.642" y2="0.782" gradientUnits="objectBoundingBox">
			<stop offset="0" stop-color="#9bcb6c"/>
			<stop offset="1" stop-color="#c4aedd"/>
		</linearGradient>
		</svg>
		<svg [ngStyle]="{'display': (value || isHovered) ? 'none' : 'inline-block', 'height': size + 'px', 'width': size + 'px'}" id="_checkboxes--inactive" data-name="$checkboxes--inactive" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g id="Caminho_7387" data-name="Caminho 7387" fill="none">
				<path d="M2.667,0H21.333A2.667,2.667,0,0,1,24,2.667V21.333A2.667,2.667,0,0,1,21.333,24H2.667A2.667,2.667,0,0,1,0,21.333V2.667A2.667,2.667,0,0,1,2.667,0Z" stroke="none"/>
				<path d="M 2.666669845581055 2 C 2.299070358276367 2 2 2.299070358276367 2 2.666669845581055 L 2 21.33333969116211 C 2 21.7009391784668 2.299070358276367 22 2.666669845581055 22 L 21.33333969116211 22 C 21.7009391784668 22 22 21.7009391784668 22 21.33333969116211 L 22 2.666669845581055 C 22 2.299070358276367 21.7009391784668 2 21.33333969116211 2 L 2.666669845581055 2 M 2.666669845581055 0 L 21.33333969116211 0 C 22.80610084533691 0 24 1.193910598754883 24 2.666669845581055 L 24 21.33333969116211 C 24 22.80610084533691 22.80610084533691 24 21.33333969116211 24 L 2.666669845581055 24 C 1.193910598754883 24 0 22.80610084533691 0 21.33333969116211 L 0 2.666669845581055 C 0 1.193910598754883 1.193910598754883 0 2.666669845581055 0 Z" stroke="none" fill="var(--check-fill)"/>
			</g>
		</svg>
		<svg [ngStyle]="{'display': (value || isHovered) ? 'inline-block' : 'none', 'height': size + 'px', 'width': size + 'px'}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
			<defs>
				<linearGradient id="linear-gradient" x1="-0.085" y1="-0.059" x2="0.642" y2="0.782" gradientUnits="objectBoundingBox">
					<stop offset="0" stop-color="#9bcb6c"/>
					<stop offset="1" stop-color="#c4aedd"/>
				</linearGradient>
			</defs>
			<g id="Grupo_33474" data-name="Grupo 33474" transform="translate(11935 631)">
				<g id="_checkboxes--inactive" data-name="$checkboxes--inactive" transform="translate(-11935 -631)">
					<path *ngIf="_withBorder" id="Caminho_7387" data-name="Caminho 7387" d="M2.667,0H21.333A2.667,2.667,0,0,1,24,2.667V21.333A2.667,2.667,0,0,1,21.333,24H2.667A2.667,2.667,0,0,1,0,21.333V2.667A2.667,2.667,0,0,1,2.667,0Z" fill="url(#linear-gradient)"/>
					<path *ngIf="!_withBorder" d="M 2.666669845581055 2 C 2.299070358276367 2 2 2.299070358276367 2 2.666669845581055 L 2 21.33333969116211 C 2 21.7009391784668 2.299070358276367 22 2.666669845581055 22 L 21.33333969116211 22 C 21.7009391784668 22 22 21.7009391784668 22 21.33333969116211 L 22 2.666669845581055 C 22 2.299070358276367 21.7009391784668 2 21.33333969116211 2 L 2.666669845581055 2 M 2.666669845581055 0 L 21.33333969116211 0 C 22.80610084533691 0 24 1.193910598754883 24 2.666669845581055 L 24 21.33333969116211 C 24 22.80610084533691 22.80610084533691 24 21.33333969116211 24 L 2.666669845581055 24 C 1.193910598754883 24 0 22.80610084533691 0 21.33333969116211 L 0 2.666669845581055 C 0 1.193910598754883 1.193910598754883 0 2.666669845581055 0 Z" stroke="none" fill="var(--check-fill)"/>
				</g>

				<rect id="Retângulo_20252" data-name="Retângulo 20252" width="20" height="20" rx="0.7" transform="translate(-11933 -629)" fill="#f6f6f6"/>
				<path id="Caminho_8770" data-name="Caminho 8770" d="M8.628.111,3.577,5.793.534,3.359a.329.329,0,0,0-.41.513L3.41,6.5a.329.329,0,0,0,.451-.038L9.12.547A.329.329,0,0,0,8.628.111Z" transform="translate(-11927.324 -622.333) rotate(3)" fill="#e4e2e2" stroke="var(--check-fill)" stroke-width="1"/>
			</g>
	</svg>

	<span [ngStyle]="{'margin': '0 ' + (size / 2.4) + 'px'}"><ng-content></ng-content></span>
</div>
