export enum GoStorkProducts {
    IVF = 'ivf',
    Surrogacy = 'surrogacy',
    EggDonors = 'egg'
}

export const PrettyProducts: Record<GoStorkProducts, string> = {
    [GoStorkProducts.IVF]: 'IVF',
    [GoStorkProducts.EggDonors]: 'Egg Donor',
    [GoStorkProducts.Surrogacy]: 'Surrogacy'
}

export enum CheckoutSources {
    EggDonor = 'egg-donor',
    TopHeader = 'top-header',
    Subscription = 'subscription'

}

const defaultDonorMessage = `Hello,
I am interested in learning more about Egg Donor #{donorId} and your agency.
Sincerely,
{name}`;

const defaultSurrogatesMessage = `Hello,
I am interested in learning more about surrogate #{surrogateId} and your agency.
Sincerely,
{name}`;

const defaultSurrogacyMessage = `Hello,
I am interested in learning more about your agency.
Sincerely,
{name}`;

export const ChatMessages = {
    defaultDonorMessage,
    defaultSurrogacyMessage,
    defaultSurrogatesMessage
};
