<div class="card" [ngSwitch]="currentQuestion">
	<div *ngSwitchCase="'name'" class="mainIcon">
		<img src="assets/home/ivf.svg" />
	</div>

	<h4 *ngSwitchCase="'name'">
		We’re here to help you find your ideal fertility clinic. <br />
		Let’s start with the basics!
	</h4>
	<h4 *ngSwitchCase="'whenToStart'">Thanks, {{ name }}! When do you want to get started with a fertility clinic?</h4>
	<h4 *ngSwitchCase="'mostImportant'">What is most important to you in a fertility clinic?</h4>
	<h4 *ngSwitchCase="'location'">Do you have a preferred location for your fertility clinic?</h4>
	<h4 *ngSwitchCase="'buildingFamily'">{{ name }}, how can a fertility clinic help you?</h4>
	<h4 *ngSwitchCase="'carrier'">Who is planning to carry the pregnancy?</h4>
	<h4 *ngSwitchCase="'gestationalCarrier'">Do you need a Gestational Carrier?</h4>
	<h4 *ngSwitchCase="'eggsOwner'">Whose eggs or embryos are you planning to use for IVF?</h4>
	<h4 *ngSwitchCase="'eggsOwnerAge'">What is the age of the person providing the {{ eggsOwnerName }}?</h4>
	<h4 *ngSwitchCase="'spermDonor'">Do you need a sperm donor?</h4>
	<h4 *ngSwitchCase="'inVirto'">Has the person providing the {{ eggsOwnerName }} pursued fertility treatment before?</h4>
	<h4 *ngSwitchCase="'relationshipStatus'">
		Are you pursuing fertility services as part of a <br />
		relationship, {{ name }}?
	</h4>
	<h4 *ngSwitchCase="'additional'">Do any of the following apply to your fertility journey?</h4>
	<h4 *ngSwitchCase="'credentials'">We are ready to find clinics for you, {{ name }}. First, let's create an account.</h4>
	<h4 *ngSwitchCase="'verification'">Verify Your Account While We Match You To Clinics</h4>
	<h4 *ngSwitchCase="'review'">Review Your Journey</h4>

	<div class="subtitle">
		<ng-container *ngSwitchCase="(['relationshipStatus', 'additional'] | includes: currentQuestion) ? currentQuestion : ''">
			These answers help us match you with clinics who excel in working with people like you.
		</ng-container>
		<ng-container *ngSwitchCase="'inVirto'">
			According to the CDC, fertility clinics’ success rates vary based on whether the IVF patient has received assistive reproductive
			treatment in the past. We use this information to help share the most accurate data with you.
		</ng-container>
		<ng-container *ngSwitchCase="'credentials'">
			Your information is 100% private and is not shared with any clinics that you do not choose to contact. We use this to help you in this
			process and save your searches for when you need them.
		</ng-container>
		<ng-container *ngSwitchCase="'eggsOwner'">
			According to the CDC, fertility clinic's success rates vary based on whether the IVF patient is using their own eggs, an egg donor or
			a surrogate. We use this information to help share the most accurate data with you.
		</ng-container>
		<ng-container *ngSwitchCase="'eggsOwnerAge'">
			GoStork will use this age in order to show you clinic data related to this age group.
		</ng-container>
	</div>

	<form id="step-{{ currentQuestion }}" [formGroup]="$any(currentStep)">
		<div class="container">
			<ng-container *ngSwitchCase="'name'">
				<mat-form-field appearance="outline">
					<mat-label>First Name</mat-label>
					<input matInput autocomplete="name" formControlName="firstName" />
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Last Name</mat-label>
					<input matInput autocomplete="family-name" formControlName="lastName" />
				</mat-form-field>
			</ng-container>

			<mat-radio-group
				*ngSwitchCase="
					(['buildingFamily', 'eggsOwner', 'carrier', 'inVirto', 'mostImportant', 'whenToStart', 'spermDonor', 'gestationalCarrier']
					| includes: currentQuestion)
						? currentQuestion
						: ''
				"
				(change)="onStepChange()"
				formControlName="text"
				color="primary"
			>
				<mat-radio-button
					*ngFor="let item of options[currentQuestion]"
					[value]="item.value"
					(click)="currentStep.get('text').value === item.value ? onStepChange() : null"
				>
					{{ item.label }}
				</mat-radio-button>
			</mat-radio-group>

			<ng-container *ngSwitchCase="'eggsOwnerAge'">
				<mat-form-field appearance="outline">
					<input matInput autocomplete="off" formControlName="text" type="number" min="0" placeholder="Age" />
				</mat-form-field>
			</ng-container>

			<ng-container *ngSwitchCase="'location'">
				<app-form-field
					type="address"
					label="City, State, ZIP code"
					controlName="text"
					(details)="fillLocationDetails($event)"
				></app-form-field>
				<button mat-button color="accent" type="button" class="darker" (click)="locationDoesntMatter()">No Preference</button>
			</ng-container>

			<ng-container *ngSwitchCase="'additional'">
				<div class="remark">Required</div>

				<div *ngFor="let question of additional" class="additional">
					<div class="remark">Required</div>

					<div class="additionalQuestion">
						<div class="additionalTitle" [innerHTML]="question.label"></div>
						<small class="additionalSubtitle">
							{{ question.subtitle }}
						</small>
					</div>
					<mat-radio-group class="simple" color="primary" [formControlName]="question.formControlName">
						<mat-radio-button class="simple" labelPosition="before" [value]="true">Yes</mat-radio-button>
						<mat-radio-button class="simple" labelPosition="before" [value]="false">No</mat-radio-button>
					</mat-radio-group>
				</div>
			</ng-container>

			<ng-container *ngSwitchCase="'relationshipStatus'">
				<div class="remark">Choose One</div>
				<mat-radio-group formControlName="text" color="primary" (ngModelChange)="refreshValidity()">
					<mat-radio-button *ngFor="let item of options[currentQuestion]" class="simple" labelPosition="before" [value]="item.value">
						{{ item.label }}
					</mat-radio-button>
				</mat-radio-group>

				<ng-container *ngIf="showPartnerBox">
					<div class="partnerName">
						<div>Your Partner's Information</div>
					</div>
					<div class="partnerBox">
						<mat-form-field appearance="outline">
							<mat-label>First Name</mat-label>
							<input matInput formControlName="firstName" />
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Last Name</mat-label>
							<input matInput formControlName="lastName" />
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Age</mat-label>
							<input matInput autocomplete="off" formControlName="age" type="number" min="0" />
						</mat-form-field>
					</div>
				</ng-container>
			</ng-container>

			<ng-container *ngSwitchCase="'credentials'">
				<mat-form-field appearance="outline">
					<mat-label>Email</mat-label>
					<input matInput formControlName="email" autocomplete="email" />
				</mat-form-field>

				<app-form-field type="phone" label="Mobile Number" controlName="phone"></app-form-field>

				<app-form-field type="date" label="Date of birth" controlName="dob"></app-form-field>

				<div>
					<mat-form-field appearance="outline">
						<mat-label>Password</mat-label>
						<input matInput type="password" formControlName="password" autocomplete="new-password" />
						<mat-error>
							<span *ngIf="currentStep.get('password').hasError('exists')">
								The account already exists, please use the correct password
							</span>
						</mat-error>
					</mat-form-field>

					<app-password-checks [value]="currentStep.get('password').value"></app-password-checks>
				</div>
			</ng-container>

			<ng-container *ngSwitchCase="'verification'">
				<app-verification-code formControlName="text" [email]="email" [phone]="phone"></app-verification-code>
				<mat-error>{{ error || '&nbsp;' }}</mat-error>
			</ng-container>

			<ng-container *ngSwitchCase="'review'">
				<div class="journeyTitle">Your Journey:</div>
				<div class="journeyContent" [innerHTML]="journeyText"></div>
			</ng-container>
		</div>

		<div *ngIf="currentQuestion === 'credentials'" class="agreements">
			By clicking Create Account, you agree to our
			<a routerLink="/policies/terms-and-conditions" target="_blank">Terms of Use</a> and
			<a routerLink="/policies/privacy-policy" target="_blank">Privacy Policy</a>. We never sell your data.
		</div>

		<button
			mat-raised-button
			*ngIf="showNext"
			color="primary"
			type="button"
			class="submit"
			[disabled]="!currentStep.valid || loading"
			(click)="onStepChange()"
		>
			{{
				currentQuestion === 'credentials'
					? 'Create Account'
					: currentQuestion === 'verification'
					? 'Confirm & View Clinics!'
					: currentQuestion === 'review'
					? 'Continue to Clinics'
					: 'Next'
			}}
		</button>

		<ng-container *ngSwitchCase="'review'">
			<div class="actualJourney">
				<div class="incorrect">
					<mat-icon>info</mat-icon>
					<span> If Your Journey is incorrect, you can change it below </span>
				</div>

				<app-ivf-journey
					(changeValue)="changeJourney($event.id, $event.value)"
					[buildingFamily]="form.value.buildingFamily.text"
					[carrier]="form.value.carrier.text"
					[eggsOwner]="form.value.eggsOwner.text"
					[eggsOwnerAge]="form.value.eggsOwnerAge.text"
					[inVirto]="form.value.inVirto.text"
				></app-ivf-journey>
			</div>
		</ng-container>

		<ng-container *ngSwitchCase="'loader'">
			<app-loader></app-loader>
		</ng-container>
	</form>

	<button mat-button *ngIf="!isFirstStep && showBack" class="back-mobile" (click)="goToPreviousStep()">
		<mat-icon>chevron_left</mat-icon>
		<span>Back</span>
	</button>
	<button mat-icon-button *ngIf="!isFirstStep && showBack" class="back" (click)="goToPreviousStep()">
		<mat-icon>chevron_left</mat-icon>
	</button>
</div>

<img class="bg" src="assets/ivf/trees.svg" />
<img class="bg bg-desktop" src="assets/ivf/trees-big.svg" />
