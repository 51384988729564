import {version} from '../version';

export const environment = {
	production: true,
	alpha: false,
	hmr: false,
	mapbox: 'pk.eyJ1IjoiZGFuZHJpaSIsImEiOiJjazI0d2hkZDYxMHR0M2dtem9jaTRrYXM3In0.BfhCB56fwkuQUtxPiC-4YQ',
	google_places_token: 'AIzaSyAqApAZCjmWZS5BMzEZUA7784J9NaZQVU0',
	version,
	fullstory: `window['_fs_debug'] = false; window['_fs_host'] = 'fullstory.com'; window['_fs_script'] = 'edge.fullstory.com/s/fs.js'; window['_fs_org'] = 'RGJ93'; window['_fs_namespace'] = 'FS'; (function(m,n,e,t,l,o,g,y){ if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;} g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[]; o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script; y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y); g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)}; g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)}; g.log = function(a,b) { g("log", [a,b]) }; g.consent=function(a){g("consent",!arguments.length||a)}; g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)}; g.clearUserCookie=function(){}; })(window,document,window['_fs_namespace'],'script','user');`,
	ga_ua: '158971191-1',
	ga4_ua: 'G-PG34N5F6YR',
	reCaptchaKey: '6LekrmYcAAAAAM_7in1x9lrlLkWUopTZXh8LK2DW',
	stripePk: 'pk_live_51L5rc6KAF38xqaFOteLDXqI9oeE7lsBSlFwbQx1ifJ0jpZL8ygqiH4u4ensSBEGN8KnmWRJkTBsFEzIyBq5B1Qmu00ZrqFv6gw',
	stripePriceId: 'price_1LD7bsKAF38xqaFOEu7UohaB',
	stripeAmount: 9900,
	stripeDate: '2022-06-20T12:00:00Z',
	appEnv: 'production'
};
