import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, QueryList, Self, ViewChildren} from '@angular/core';
import {ControlValueAccessor, FormBuilder, NgControl, Validators} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';

import {Observable, Subject, Subscription, timer} from 'rxjs';
import {share, take, map} from 'rxjs/operators';
import {AuthStoreService} from '@/services/auth.service';

@Component({
	selector: 'app-verification-code',
	templateUrl: './verification-code.component.html',
	styleUrls: ['./verification-code.component.scss'],
})
export class VerificationCodeComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input()
	phone: string;
	@Input()
	email: string;

	codeControl = this.fb.control('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);

	_onChange: (_: any) => void = null;
	_onTouched: () => void = null;

	changeSubscription: Subscription;
	timer$: Observable<number>;

	constructor(private fb: FormBuilder, private authService: AuthStoreService, @Optional() @Self() public ngControl: NgControl) {
		if (this.ngControl != null) this.ngControl.valueAccessor = this;
	}

	resendCode(type: 'phone' | 'email') {
		this.timer$ = timer(0, 1000).pipe(
			share(),
			take(61),
			map((value) => 60 - value),
		);
		this.authService.resendCode(this.email, type).subscribe();
	}

	writeValue(obj: any) {
		this.codeControl.setValue(obj);
	}

	registerOnChange(fn: (_: any) => void) {
		this._onChange = fn;
	}

	registerOnTouched(fn: any) {
		this._onTouched = fn;
	}

	ngOnInit(): void {
		this.changeSubscription = this.codeControl.valueChanges.subscribe(() => {
			console.log(`[VerificationCodeComponent] value => ${this.codeControl.value}`);
			if (this._onChange) this._onChange(this.codeControl.value);
			if (this._onTouched) this._onTouched();
		});
	}

	ngOnDestroy() {
		this.changeSubscription?.unsubscribe();
	}
}

@Component({
	selector: 'app-verification-code-control',
	template: `
		<input
			*ngFor="let field of fields.controls | slice: 0:3"
			#control
			type="text"
			inputmode="numeric"
			autocomplete="one-time-code"
			placeholder="_"
			data-lpignore="true"
			[formControl]="$any(field)"
			(input)="onInput($any($event))"
			(keypress)="onKeypress($event)"
			(keydown)="onKeydown($event)"
			(paste)="onPaste($event)"
		/>
		<span>-</span>
		<input
			*ngFor="let field of fields.controls | slice: 3"
			#control
			type="text"
			inputmode="numeric"
			autocomplete="one-time-code"
			placeholder="_"
			data-lpignore="true"
			[formControl]="$any(field)"
			(input)="onInput($any($event))"
			(keypress)="onKeypress($event)"
			(keydown)="onKeydown($event)"
			(paste)="onPaste($event)"
		/>
	`,
	styles: [
		`
			:host {
				display: flex;
				flex-wrap: none;
				justify-content: center;
			}

			input {
				border: none;
				background: none;
				padding: 0;
				outline: none;
				font: inherit;
				text-align: center;
				width: 20px;
			}

			span {
				padding: 0 10px;
			}
		`,
	],
	providers: [{provide: MatFormFieldControl, useExisting: VerificationCodeControlComponent}],
})
export class VerificationCodeControlComponent implements OnDestroy, MatFormFieldControl<string>, ControlValueAccessor {
	static nextId = 0;

	@HostBinding() id = `example-tel-input-${VerificationCodeControlComponent.nextId++}`;

	@ViewChildren('control')
	controls: QueryList<ElementRef<HTMLInputElement>>;

	stateChanges = new Subject<void>();

	fields = this.fb.array(
		Array.from({length: 6}).map(() =>
			this.fb.control(null, [Validators.required, Validators.min(0), Validators.max(9), Validators.maxLength(1)]),
		),
	);

	get value() {
		return this.fields.value.join('');
	}

	set value(code: string) {
		this.fields.patchValue(code?.split('').slice(0, this.fields.length) || []);
		this.stateChanges.next();
		if (this._onChange) this._onChange(code);
		if (this._onTouched) this._onTouched();
	}

	@Input()
	get placeholder() {
		return this._placeholder;
	}
	set placeholder(plh) {
		this._placeholder = plh;
		this.stateChanges.next();
	}

	focused = false;
	touched = false;

	get empty() {
		return this.fields.value.filter(Boolean).length === 0;
	}

	@HostBinding('class.floating')
	get shouldLabelFloat() {
		return this.focused || !this.empty;
	}

	@Input()
	get required() {
		return this._required;
	}
	set required(req) {
		this._required = coerceBooleanProperty(req);
		this.stateChanges.next();
	}

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}
	set disabled(value: boolean) {
		this._disabled = coerceBooleanProperty(value);
		if (this._disabled) {
			this.fields.disable();
		} else {
			this.fields.enable();
		}
		this.stateChanges.next();
	}

	get errorState(): boolean {
		return this.fields.invalid && this.touched;
	}

	controlType = 'verification-code-control-input';

	_onChange: (_: any) => void = null;
	_onTouched: () => void = null;

	private _disabled = false;
	private _required = false;
	private _placeholder: string;

	constructor(private fb: FormBuilder, @Optional() @Self() public ngControl: NgControl, private _elementRef: ElementRef) {
		if (this.ngControl != null) {
			this.ngControl.valueAccessor = this;
		}
	}

	onKeypress(event: KeyboardEvent) {
		if (event.metaKey) {
			return;
		}

		const code = event.key.charCodeAt(0);
		if ((code > 57 || code < 48) && code !== 69) {
			return false;
		}

			const _controls = this.controls.toArray();
			const focused = _controls.findIndex((item) => item.nativeElement === document.activeElement);

			setTimeout(() => {
			if (this._onChange) this._onChange(this.fields.value.join(''));
			if (this._onTouched) this._onTouched();

			if (_controls[focused + 1]) {
				_controls[focused + 1].nativeElement.select();
			} else {
			//	_controls[focused - 1]?.nativeElement.focus();
				_controls[focused].nativeElement.select();
			}
		},29);
	}

	onKeydown(event: KeyboardEvent) {
		if (event.code === 'Backspace' || event.code === '') {
			const _controls = this.controls.toArray();
			const focused = _controls.findIndex((item) => item.nativeElement === document.activeElement);

			if (this._onChange) this._onChange(this.fields.value.join(''));
			if (this._onTouched) this._onTouched();

			setTimeout(() => {
			//	_controls[focused - 1]?.nativeElement.focus();
				_controls[focused - 1]?.nativeElement.select();
			},29);
		}
	}

	//onFocus(event: FocusEvent) {
	//	 setTimeout(() => (event.target as HTMLInputElement).select());
	//}

	onInput(event: InputEvent) {
		if (event?.data?.length === 6) {
			this.value = event.data;
		} else if (event.target) {
			setTimeout(() => {
				const value = (event.target as HTMLInputElement).value;
				if (value?.length === 6) {
					this.value = value;
				}
			});
		}

		event.preventDefault();
	}

	onPaste(event: ClipboardEvent) {
		if (event.clipboardData.files.length) {
			return false;
		}
		const data = (event.clipboardData.getData('text/plain') || '').replace(/[^0-9]/g, '');
		if (data && !isNaN(+data)) {
			this.value = data;
		}

		return false;
	}

	setDescribedByIds(ids: string[]) {
		const controlElement = this._elementRef.nativeElement.querySelector(`.${this.controlType}-container`);
		controlElement?.setAttribute('aria-describedby', ids.join(' '));
	}

	onContainerClick(event: MouseEvent) {
		if ((event.target as Element).tagName.toLowerCase() !== 'input') {
			this._elementRef.nativeElement.querySelector('input').focus();
		}
	}

	onFocusIn(event: FocusEvent) {
		if (!this.focused) {
			this.focused = true;
			this.stateChanges.next();
		}
	}

	onFocusOut(event: FocusEvent) {
		if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
			this.touched = true;
			this.focused = false;
			if (this._onTouched) {
				this._onTouched();
			}
			this.stateChanges.next();
		}
	}

	registerOnChange(fn: (_: any) => void) {
		this._onChange = fn;
	}

	registerOnTouched(fn: any) {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	writeValue(obj: any) {
		this.value = obj;
	}

	ngOnDestroy() {
		this.stateChanges.complete();
	}
}
