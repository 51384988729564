/* eslint-disable @typescript-eslint/no-namespace */

import {InjectionToken} from '@angular/core';
import {LabelValueIcon} from './common.model';

export namespace NIvfFilters {
	export enum IvfService {
		IntrauterineInsemination = 'intrauterine_insemination',
		EggDonation = 'egg_donation',
		SpermDonation = 'sperm_donation',
		Surrogacy = 'surrogacy',
		Accupuncture = 'accupuncture',
		EmbryoGenderSelection = 'embryo_gender_selection',
		MaleFertilityTreatments = 'male_fertility_treatments',
		EmbryoDonation = 'embryo_donation',
		EggFreezing = 'egg_freezing',
		MultipleEmbryoTransfer = 'multiple_embryo_transfer',
		GeneticTesting = 'genetic_testing',
		FemaleFertilityTreatments = 'female_fertility_treatments',
		ReciprocalIvf = 'reciprocal_ivf',
		MentalHealth = 'mental_health_counseling',
		HivPatients = 'hiv_patients',
		EmbryoFreezing = 'embryo_freezing',
		AllowingEDSGC = 'allowing_egg_donor___sperm_donor___gestational_carrier_cycle',
		HaveBipocPhysicians = 'have_bipoc_physicians',
	}

	export enum IvfSort {
		Date,
		EggFreezingCycles,
		TransferWithSurrogate,
		TransferWithDonor,
		Transfer,
		Cycles,
		BabiesBorn,
		Name,
		Rating,
		JourneyCost,
		SuccessOwnEggs,
		SuccessOwnEggsAny_35,
		SuccessOwnEggs35_37,
		SuccessOwnEggs38_40,
		SuccessOwnEggs41_42,
		SuccessOwnEggs42_Any,
		SuccessDonorEggs,
		SuccessSurrogate,
		Location,

		SurrogacyOwnEggsBabies,
		SurrogacyDonatedEggsBabies,
		SurrogacyDonatedEmryosBabies,
		TransferWithDonorEmbryo,

		SuccessDonorEmryos,
		SuccessSurrogacyOwnEggs,
		SuccessSurrogacyDonatedEggs,
		successSurrogacyDonatedEmbryos,

		Success,
	}

	export enum BuildingFamily {
		Pregnancy,
		Freezing,
	}

	export enum EggsOwner {
		MyEggs,
		DonorEggs,
		DobnorEmbryo,
		MyEmbryo,
	}

	export enum PregnancyCarreir {
		Self,
		GestationalCarrier,
		Reciprocal,
	}

	export enum InsuranceType {
		Self,
		Later,
		Specific,
	}

	export enum SpermDonor {
		Yes,
		No,
		NotSure,
	}

	export enum InVirto {
		Yes,
		No,
		NotSure,
	}

	export enum Gestational {
		Yes,
		No,
		NotSure,
	}
}

export interface InsuranceValue {
	carrier: string;
	plan: string;
}

export interface Insurance {
	type: NIvfFilters.InsuranceType;
	insurance: InsuranceValue;
}

export const IVF_SERVICES = new InjectionToken<LabelValueIcon[]>('');
export const IVF_ACCREDITATIONS = new InjectionToken<LabelValueIcon[]>('');
export const IVF_MEMBERSHIPS = new InjectionToken<LabelValueIcon[]>('');
