import {Platform} from '@angular/cdk/platform';
import {HttpEventType} from '@angular/common/http';
import {ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DateTime} from 'luxon';
import {BehaviorSubject, interval, Observable, of, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, exhaustMap, filter, first, map, shareReplay, startWith, switchMap, tap, throttleTime} from 'rxjs/operators';
import {ProviderType} from 'src/app/config';
import {ServiceMessageType} from 'src/app/models/chat.model';

import {AuthStoreService} from 'src/app/services/auth.service';
import {CompaniesStoreService, ICompany} from 'src/app/services/company.service';
import {ContactAgencyService} from 'src/app/services/contact-agency.service';
import {DealsService, DetailsForProvider, UnifiedDealStatus} from 'src/app/services/deals.service';
import {DonorsStoreService, IDonor} from 'src/app/services/donors.service';
import {ProvidersStoreService} from 'src/app/services/providers.service';
import {UtilsService} from 'src/app/services/utils.service';

import {ParentStoreService} from '@/services/parent.service';
import {ISurrogate, SurrogatesStoreService} from '@/services/surrogates.service';
import {AttachmentType, Author, Chat, ChatService, Message} from '../../services/chat.service';
import {IvfService} from '../ivf/ivf.service';
import {options} from '../ivf/questions';

enum ChatScreen {
	ADMIN = -1,
	LIST,
	CHAT,
	DETAILS,
}

enum FilterWith {
	UNREAD_MESSAGES = 'unread-messages',
}

interface ChatGroup {
	length: number;
	title: string;
	last: Chat;
	all: Chat[];
}

const PIXEL_FROM_BOTTOM_TO_LOCK = 8;

const buildChatTitle = (chat: Chat, userType: 'admin' | 'parent' | 'provider') => {
	if (chat.isSupportChat) {
		return userType === 'admin'
			? `Support - ${
					chat.provider?.company_name
						? `${chat.provider_contact?.first_name || ''} ${chat.provider_contact?.last_name || ''} from ${
								chat.provider?.company_name
						  }`
						: `${chat.user?.first_name || ''} ${chat.user?.last_name || ''}`
			  }`
			: 'GoStork Support';
	}
	if (userType === 'parent') {
		if (chat.egg_donor) {
			return `Donor #${chat.egg_donor_id}`;
		}
		if (chat.surrogate) {
			return `Surrogate #${chat.surrogate_id}`;
		}
		return chat.provider?.company_name;
	}
	return `${chat.user.first_name} ${chat.user.last_name}`;
};

const buildChatSubTitle = (chat, userType: 'parent' | 'provider' | 'admin') => {
	if (userType !== 'parent') {
		if (chat.egg_donor_id) {
			return `- Donor #${chat.egg_donor_id}`;
		}
		if (chat.surrogate_id) {
			return `- Surrogate #${chat.surrogate_id}`;
		}
	}
	if (chat.egg_donor || chat.surrogate_id) {
		return `from ${chat.provider.company_name}`;
	}
	return '';
};

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss'],
	providers: [ContactAgencyService],
})
export class ChatComponent implements OnInit, OnDestroy {
	@ViewChild('messagesList')
	messagesListRef: ElementRef<HTMLElement>;
	@ViewChild('area')
	textAreaRef: ElementRef<HTMLTextAreaElement>;

	@ViewChild('detailsCalendar')
	detailsCalendarRef: ElementRef<HTMLTextAreaElement>;

	@HostBinding('attr.ios')
	isIOS = this.platform.IOS;
	@HostBinding('attr.type')
	userType: 'parent' | 'provider' | 'admin';

	ProviderType = ProviderType;
	AttachmentType = AttachmentType;
	ServiceMessageType = ServiceMessageType;
	IvfOptions = Object.fromEntries(
		Object.entries(options).map(([key, value]) => [
			key,
			Object.fromEntries(value.map(({label, value: optionValue}) => [optionValue, label])),
		]),
	);
	FilterEnum = {
		...ProviderType,
		...FilterWith,
	};
	messageListScrolled = false;
	scrollTimer: Subscription;
	Author = Author;
	meAuthor: Author;

	isConnecting$ = this.chatService.socketState.pipe(map((isConnected) => !isConnected));

	chatId$ = this.route.paramMap.pipe(
		map((paramMap) => paramMap.get('chatId')),
		distinctUntilChanged(),
		tap((chatId) => {
			this.activeScreen = chatId ? ChatScreen.CHAT : ChatScreen.LIST;
			this.chatService.lastOpened = chatId || null;
			this.userInvited = false;
			this.messageListScrolled = false;
		}),
		shareReplay(1),
	);
	chats$ = this.chatService.chats$.pipe(
		map((chats) =>
			chats.map((chat) => {
				chat.title = buildChatTitle(chat, this.userType);
				chat.subtitle = buildChatSubTitle(chat, this.userType);
				if (chat.provider?.provider_contacts) {
					const finalContact = [];
					chat.provider.provider_contacts =
						this.userType === 'admin'
							? chat.provider.provider_contacts
							: chat.provider.provider_contacts.filter(
									(contact) => contact.calendar_id && (this.userType === 'provider' || contact.show_calendar),
							  );
					for (const providerContact of chat.provider.provider_contacts) {
						if (providerContact && providerContact.location && providerContact.location.length > 0) {
							for (const locationContact of providerContact.location) {
								if (
									locationContact &&
									chat.location &&
									locationContact.city === chat.location.city &&
									locationContact.state === chat.location.state &&
									locationContact.country === chat.location.country
								) {
									finalContact.push(providerContact);
								}
							}
						}
					}
					if (finalContact && finalContact?.length > 0) {
						chat.provider.provider_contacts = finalContact;
					}
				}
				chat.img = this.ivfService.prepareImage(chat.provider?.ivf)?.img || this.getChatImg(chat);
				chat.location = chat.location;
				return chat;
			}),
		),
		switchMap((chats) =>
			this.providerFilter$.pipe(
				map((filterValue) => (filterValue ? chats.filter((item) => item.provider_id === filterValue) : chats)),
			),
		),
		switchMap((chats) =>
			this.typeFilter$.pipe(
				map((filterValue) => (filterValue ? chats.filter((chat) => this.filterChatByType(chat, filterValue)) : chats)),
			),
		),
	);
	chatGroups$ = this.chats$.pipe(
		map((chats) => {
			const result = new Map<string, ChatGroup>();
			for (const item of chats) {
				const key = ((this.userType === 'parent' ? item.provider_id : item.user_id) || 'support').toString();
				if (!item.egg_donor_id && !item.surrogate_id) {
					if (!item.location) {
						result.set(item.id, {length: 1, title: '', last: item, all: [item]});
						continue;
					}
				}
				let exists = result.get(key);
				if (!exists) {
					exists = {length: 0, last: null, title: '', all: []};
					result.set(key, exists);
				}
				if (item.location) {
					item.title = item.location.city + ', ' + item.location.state;
				}
				exists.all.push(item);
				exists.last = exists.all[0];
				exists.length++;
				if (exists.length >= 1) {
					exists.title =
						this.userType === 'parent' ? item.provider?.company_name : `${item.user.first_name} ${item.user.last_name}`;
				}
			}
			return Array.from(result.values());
		}),
	);
	selectedChat$ = this.chatId$.pipe(
		switchMap((id) =>
			this.chats$.pipe(
				map((chats) => chats.find((chat) => chat.id === id)),
				distinctUntilChanged(
					(a, b) => a && b && a.id === b.id && a.provider?.provider_contacts?.length === b.provider?.provider_contacts?.length,
				),
				tap((chat) => {
					if (!chat) {
						this.isScrollable = false;
						return;
					}
					this.isScrollable = true;
					this.chatService.loadMessages(chat.id).subscribe();
					this.chatService.loadProviderContacts(chat.id).subscribe();
					this.names = {
						[Author.USER]: chat.user && `${chat.user.first_name} ${chat.user.last_name}`,
						[Author.PROVIDER]: chat.provider?.company_name,
					};
					this.images = {
						[Author.USER]:
							chat.user &&
							`https://eu.ui-avatars.com/api/?length=1&size-80&rounded=true&bold=true&background=F9F2FF&color=424B5E&name=${chat.user.first_name}`,
						[Author.PROVIDER]: this.ivfService.prepareImage(chat.provider?.ivf)?.img || this.getChatImg(chat),
					};
					this.detailsType = chat.deal.type;
					this.hasSurrogate = !!chat.surrogate_id;
				}),
			),
		),
		shareReplay(1),
	);
	chatChanges = false;
	skipScrollEvent = false;
	selectedChatMessages$ = this.selectedChat$.pipe(
		distinctUntilChanged((x, y) => x?.id === y?.id),
		tap(() => (this.chatChanges = true)),
		switchMap((chat) => (chat ? this.chatService.getMessages$(chat.id) : of([] as Message[]))),
		map((messages) => {
			const result = [] as Array<Message>;
			let lastDate = '';
			let lastMessage: Message;
			for (const message of messages) {
				if (!message.read && message.author !== this.meAuthor) {
					this.chatService.markAsRead(message.id);
				}

				if (
					this.meAuthor === Author.USER &&
					[ServiceMessageType.NoPhonesProvider, ServiceMessageType.DealWithOtherAgencySigned].includes(message.type)
				) {
					continue;
				}

				const currentDate = DateTime.fromISO(message.created_at as string).toISODate();
				if (currentDate !== lastDate) {
					result.push({
						id: message.created_at,
						type: ServiceMessageType.Date,
						typeData: {date: new Date(message.created_at)},
					} as unknown as Message);
				}
				result.push(message);
				lastDate = currentDate;
				if (!message.type && message.author === this.meAuthor) {
					message.status = message.id === null ? 'Sending' : message.read ? 'Seen' : 'Delivered';
					lastMessage = message;
				}
			}

			if (lastMessage) {
				lastMessage.last = true;
			}

			return result;
		}),
		tap(() => {
			const el = this.messagesListRef?.nativeElement;
			if (el) {
				this.renderer.setStyle(el, 'overflow', 'hidden');
				if (!el['scrollListenerAttached']) {
					console.log('attached listener');
					el.addEventListener('scroll', () => {
						this.isScrolling = true;

						clearTimeout(this.scrollTimeout);

						// @ts-ignore
						this.scrollTimeout = setTimeout(() => {
							this.isScrolling = false;
						}, 800);
					});
					el['scrollListenerAttached'] = true;
				}

				const scrollPositionBeforeUpdate = el.scrollHeight - el.scrollTop - el.clientHeight;
				// eslint-disable-next-line no-console
				console.time('[ChatComponent] render list');
				setTimeout(() => {
					// eslint-disable-next-line no-console
					console.timeEnd('[ChatComponent] render list');
					this.skipScrollEvent = true;
					if (this.chatChanges) {
						setTimeout(() => this.updateScrolling(true));
						this.chatChanges = false;
					} else if (el) {
						this.messagesListRef.nativeElement.scrollTop = el.scrollHeight - scrollPositionBeforeUpdate - el.clientHeight;
					}
					setTimeout(() => (this.skipScrollEvent = false), 1000);
					this.renderer.setStyle(el, 'overflow', '');
				});
			}
		}),
	);
	withAppointment$: Observable<boolean>;
	userDetails$: Observable<DetailsForProvider>;
	selectedChatContacts$ = this.selectedChat$.pipe(
		map(
			(chat) =>
				chat?.provider?.original_contacts?.reduce(
					(obj, item) => ((obj[item.id] = item), obj),
					{} as Record<string, Chat['provider']['provider_contacts'][0]>,
				) || {},
		),
		startWith({}),
	);

	providers = [];

	typeFilter$ = new BehaviorSubject<FilterWith | ProviderType>(null);
	providerFilter$ = new BehaviorSubject<number>(null);
	withCalendar$ = this.selectedChat$.pipe(
		map((chat) => !!chat?.provider?.provider_contacts?.find((item) => item.id === this.authService.value.id)?.calendar_id),
	);

	dealStatuses = Object.values(UnifiedDealStatus).map((label) => ({label, value: label}));
	surrogacyDealStatuses = this.dealStatuses.filter(
		(item) =>
			![
				UnifiedDealStatus.DONOR_MATHING,
				UnifiedDealStatus.EGG_DONOR_CONTRACT_SIGNING,
				UnifiedDealStatus.EGG_DONOR_UNAVAILABLE,
				UnifiedDealStatus.NEGOTIATING_EGG_DONOR_CONTRACT,
			].includes(item.label),
	);
	userInvited = false;

	chatGroupsHidden: Record<string, boolean> = {};

	uploadProgress = 0;
	uploadedFileLink$ = this.chatService.file$;
	uploadedFileTrusted$ = this.uploadedFileLink$.pipe(
		map((file) =>
			file
				? {
						...file,
						mime: file.mimetype.split('/')[0],
						path: `images/${file.path}`,
				  }
				: null,
		),
	);

	currentMessage = '';
	_names = {
		[Author.USER]: '',
		[`${Author.USER}_short`]: '',
		[Author.PROVIDER]: '',
		[`${Author.PROVIDER}_short`]: '',
		[Author.SYSTEM]: 'GoStork Support',
		[Author.SUPPORT]: 'GoStork Support',
	};
	hasSurrogate = false;
	private isScrolling: boolean;
	private scrollTimeout: number;
	get names() {
		return this._names;
	}
	set names(value: Partial<Record<Author, string>>) {
		for (const key in value) {
			const t: string[] = value[key]?.split(' ') || [''];
			const rest = t.slice(1);
			value[`${key}_short`] = rest.reduce((short, item) => `${short} ${item[0]}.`, t[0]);
		}
		this._names = {
			...this._names,
			...value,
		};
	}
	_images = {
		[Author.USER]: '',
		[Author.PROVIDER]: '',
		[Author.SYSTEM]: 'assets/home/logo-stork.png',
		[Author.SUPPORT]: 'assets/home/logo-stork.png',
	};
	get images() {
		return this._images;
	}
	set images(value: Partial<Record<Author, string>>) {
		this._images = {
			...this._images,
			...value,
		};
	}
	detailsType: ProviderType;
	details$: Observable<IDonor | ICompany | ISurrogate> = this.selectedChat$.pipe(
		distinctUntilChanged((x, y) => x?.id === y?.id),
		switchMap((chat) => {
			if (chat && !chat.isSupportChat) {
				if (chat.egg_donor_id) {
					return this.donorsService.getDonorById(chat.egg_donor_id).pipe(
						map((res) => res[0]),
						map((donor) => ({
							...donor,
							ethnicity: donor.ethnicity?.join(', ') || '',
							race: donor.race?.join(', ') || '',
						})),
					);
				}
				if (chat.surrogate_id) {
					return this.surrogateService.getSurrogateById(chat.surrogate_id).pipe(map((res) => res[0]));
				}
				if (chat.deal.type === ProviderType.SURROGACY_AGENCY) {
					return this.companiesService.getCompanyById(chat.provider_id).pipe(map((res) => res[0]));
				}
				return this.ivfService.getOne(chat.provider.ivf?.id).pipe(
					map((ivf) => this.ivfService.prepareImage(ivf)),
					map((ivf) => this.ivfService.prepareName(ivf)),
					map((ivf) => ({...ivf, link: this.ivfService.createProfileLink(ivf)})),
				);
			}
			return of(null);
		}),
	) as Observable<IDonor | ICompany>;

	_activeScreen = ChatScreen.LIST;
	get activeScreen() {
		return this._activeScreen;
	}
	set activeScreen(value: ChatScreen) {
		this._activeScreen = value;
		this.elementRef.nativeElement.scroll({
			left: (value + (this.userType === 'admin' ? 1 : 0)) * this.elementRef.nativeElement.offsetWidth,
		});
	}

	_isScrollable = false;
	get isScrollabel() {
		return this._isScrollable;
	}
	set isScrollable(value: boolean) {
		this.utilService.isSmallScreen.pipe(first()).subscribe((isSmall) => (this._isScrollable = isSmall ? value : false));
	}

	listScrollSubject = new Subject<void>();
	isListUpdating = false;
	isTouching = false;

	messageReceiver: Author.USER | number = null;

	constructor(
		private chatService: ChatService,
		private dealsService: DealsService,
		private route: ActivatedRoute,
		private parentStore: ParentStoreService,
		private authService: AuthStoreService,
		private elementRef: ElementRef<HTMLElement>,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		private donorsService: DonorsStoreService,
		private surrogateService: SurrogatesStoreService,
		private companiesService: CompaniesStoreService,
		public utilService: UtilsService,
		private contactAgencyService: ContactAgencyService,
		private providersService: ProvidersStoreService,
		private platform: Platform,
		private renderer: Renderer2,
		private ivfService: IvfService,
	) {}

	@HostBinding('style.overflowX')
	get isOverflow() {
		return this.isScrollable ? 'scroll' : 'hidden';
	}

	@HostListener('scroll')
	onScroll() {
		if (this.isScrollable && this.elementRef.nativeElement.scrollLeft < 50) {
			this.router.navigate(['/', 'chat']);
		}
	}

	@HostListener('touchstart')
	onTouchStart() {
		this.isTouching = true;
	}

	@HostListener('touchend')
	@HostListener('touchcancel')
	onTouchEnd() {
		this.isTouching = false;
		this.updateScrolling();
	}

	openLeftMenuForAdmin() {
		this.activeScreen = ChatScreen.ADMIN;
	}

	onKeyDown(event: KeyboardEvent) {
		if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
			this.sendMessage();
		}
	}

	orderBy() {
		return 1;
	}

	trackBy(index: number, message: Message) {
		return message.id;
	}

	filterChatByType(chat: Chat, type: FilterWith | ProviderType, force = false) {
		if (type === FilterWith.UNREAD_MESSAGES) {
			const lastMessage = chat.messages && chat.messages[0];
			if (!lastMessage) {
				return false;
			}

			if (!force && this.chatService.lastOpened === chat.id) {
				return true;
			}

			return !lastMessage.read && lastMessage.author !== this.meAuthor;
		}
		return chat.deal?.type === type;
	}

	updateHeightOfTextarea(target: HTMLTextAreaElement) {
		const el = this.messagesListRef.nativeElement;
		const wasOnBottom = Math.abs(el.scrollHeight - el.offsetHeight - el.scrollTop) < PIXEL_FROM_BOTTOM_TO_LOCK;

		const rows = target.value?.split('\n') || [];
		const rowsHeight = rows.length * 1.2;
		target.style.height = `${rowsHeight}em`;
		if (target.scrollHeight > rowsHeight) {
			target.style.height = `${target.scrollHeight}px`;
		}
		if (wasOnBottom) {
			this.updateScrolling(true);
		}
	}

	/*
		This method scrolls to latest message and locks the scroll position when arriving at the bottom.
	 */
	updateScrolling(force = false) {
		const el = this.messagesListRef?.nativeElement;
		if (!el) {
			this.messageListScrolled = false;
			return;
		}
		const isOnBottom = Math.abs(el.scrollHeight - el.clientHeight - el.scrollTop) < PIXEL_FROM_BOTTOM_TO_LOCK;
		const avoidScrollLocking = this.isTouching || this.isScrolling;
		if (force || (isOnBottom && !avoidScrollLocking)) {
			this.messageListScrolled = false;
		}
		if (!this.messageListScrolled && this.messagesListRef) {
			this.skipScrollEvent = true;
			this.messagesListRef.nativeElement.scrollTop = this.messagesListRef.nativeElement.scrollHeight;
			setTimeout(() => (this.skipScrollEvent = false), 1000);
		}
	}

	sendMessage() {
		if (!this.currentMessage && !this.uploadedFileLink$.value) {
			return;
		}

		this.selectedChat$
			.pipe(first())
			.subscribe((chat) =>
				this.userType === 'admin' && !chat.isSupportChat
					? this.chatService
							.createSupportChat(
								this.messageReceiver === Author.USER ? Author.USER : Author.PROVIDER,
								this.messageReceiver === Author.USER ? chat.user_id : this.messageReceiver,
								this.currentMessage,
							)
							.subscribe()
					: this.chatService.addMessage(
							chat.id,
							this.currentMessage || this.uploadedFileLink$.value.filename,
							this.uploadedFileLink$.value?.path,
					  ),
			);
		this.uploadProgress = 0;
		this.currentMessage = '';
		setTimeout(() => {
			this.updateHeightOfTextarea(this.textAreaRef.nativeElement);
			this.chatChanges = true;
			this.updateScrolling(true);
		});
	}

	openDetails() {
		this.activeScreen = ChatScreen.DETAILS;
	}

	openDetailsOnCalendar() {
		this.openDetails();
		setTimeout(() => this.detailsCalendarRef?.nativeElement?.scrollIntoView(), 500);
	}

	closeDetails() {
		this.activeScreen = ChatScreen.CHAT;
	}

	uploadFile(files: FileList) {
		if (!files.length) {
			return;
		}
		const file = files.item(0);
		console.log('file =>', file);

		if (file.size > 0xc800000) {
			console.warn('file is too big');
			return;
		}

		this.uploadProgress = 0;
		this.selectedChat$
			.pipe(
				first(),
				switchMap((chat) => this.chatService.uploadFile(chat.id, file)),
			)
			.subscribe((event) => {
				if (event.type === HttpEventType.UploadProgress && event.total) {
					this.uploadProgress = Math.round((event.loaded / event.total) * 100);
				} else if (event.type === HttpEventType.Response && event.status === 204) {
					this.uploadProgress = 100;
				} else if (event.type === HttpEventType.Response) {
					this.uploadProgress = 0;
				}
				this.changeDetectorRef.detectChanges();
			});
	}

	removeAttachment() {
		this.uploadProgress = 0;
		this.uploadedFileLink$.next(null);
	}

	openFile(url: string) {
		const link = document.createElement('a');
		link.href = url;
		link.target = '_blank';
		link.click();
	}

	downloadFile(url: string) {
		const link = document.createElement('a');
		link.href = url;
		link.target = '_blank';
		link.download = url.replace(/(.*)\//, '');
		link.click();
	}

	toggleGroup(chatId: string) {
		this.chatGroupsHidden[chatId] = !this.chatGroupsHidden[chatId];
	}

	changeTypeFilter(type: FilterWith | ProviderType) {
		if (type) {
			this.selectedChat$.pipe(first()).subscribe((chat) => {
				if (chat && !this.filterChatByType(chat, type, true)) {
					this.router.navigate(['/', 'chat']).then(() => this.typeFilter$.next(type));
				} else {
					this.typeFilter$.next(type);
				}
			});
		} else {
			this.typeFilter$.next(null);
		}
	}

	changeProviderFilter(id: number | null) {
		if (id) {
			this.selectedChat$.pipe(first()).subscribe((chat) => {
				if (chat && chat.provider_id !== id) {
					this.router.navigate(['/', 'chat']).then(() => this.providerFilter$.next(id));
				} else {
					this.providerFilter$.next(id);
				}
			});
		} else {
			this.providerFilter$.next(id);
		}
		this.activeScreen = ChatScreen.LIST;
	}

	deleteAppointment({time, calendarId}: {time: DateTime; calendarId: string}) {
		this.selectedChat$.pipe(first()).subscribe(({id: chatId}) =>
			this.chatService.addMessage(
				chatId,
				ContactAgencyService.buildServiceMessage(ServiceMessageType.CancelAppointment, {
					calendarId,
					time: time.toISO() as string,
				}),
			),
		);
	}

	updateAppointment({time, calendarId}: {time: DateTime; calendarId: string}) {
		this.selectedChat$.pipe(first()).subscribe(({id}) =>
			this.chatService.addMessage(
				id,
				ContactAgencyService.buildServiceMessage(ServiceMessageType.UpdateAppointment, {
					time: time.toISO(),
					calendarId,
				}),
			),
		);
	}

	createAppointment({time, calendarId}: {time: DateTime; calendarId: string}) {
		this.selectedChat$.pipe(first()).subscribe(({id}) =>
			this.chatService.addMessage(
				id,
				ContactAgencyService.buildServiceMessage(ServiceMessageType.CreatedAppointment, {
					time: time.toISO(),
					calendarId,
				}),
			),
		);
	}

	updateDealStatus(status: UnifiedDealStatus) {
		if (this.userType === 'admin') {
			return;
		}
		this.selectedChat$
			.pipe(
				first(),
				switchMap((chat) => this.dealsService.changeDealStatus(chat.deal_id, status)),
			)
			.subscribe();
	}

	inviteUser() {
		if (this.userType === 'admin') {
			return;
		}
		this.userInvited = true;
		this.selectedChat$.pipe(first()).subscribe((chat) => {
			const provider =
				chat.provider.provider_contacts?.find((item) => item.id === this.authService.value.id) ||
				chat.provider.provider_contacts[Math.floor(Math.random() * chat.provider.provider_contacts?.length)];
			this.chatService.addMessage(
				chat.id,
				ContactAgencyService.buildServiceMessage(ServiceMessageType.InviteForAppointment, {
					calendarId: provider.calendar_id,
				}),
			);
		});
	}

	clickWithDelay(element: HTMLElement, timeout = 0) {
		setTimeout(() => element.click(), timeout);
	}

	rateMessage(messageId: string, reaction: boolean) {
		this.chatService.reactToMessage(messageId, reaction);
	}

	ngOnInit() {
		document.body.classList.add('hide-hubspot');

		this.userType = this.authService.getType();
		this.meAuthor = this.userType === 'admin' ? Author.SUPPORT : this.userType === 'provider' ? Author.PROVIDER : Author.USER;
		this.withAppointment$ =
			this.userType === 'parent'
				? this.selectedChat$.pipe(
						distinctUntilChanged((x, y) => x?.id === y?.id),
						filter((chat) => !!chat),
						map((chat) => ({...chat, contacts: chat.provider?.provider_contacts?.map((item) => item.id)})),
						switchMap((chat) =>
							this.contactAgencyService
								.getAppointments()
								.pipe(
									map((appointments) =>
										appointments.filter(
											(item) =>
												chat.contacts?.includes(item.provider_contact_id) &&
												(chat.egg_donor_id ? item.egg_donor_id === chat.egg_donor_id : true),
										),
									),
								),
						),
						map((appointments) => !!appointments.filter(Boolean).length),
						shareReplay(1),
				  )
				: null;
		this.userDetails$ =
			this.userType === 'parent'
				? null
				: this.selectedChat$.pipe(
						distinctUntilChanged((x, y) => x?.id === y?.id),
						switchMap((chat) =>
							chat && !chat.isSupportChat
								? this.dealsService.getDetailsForProvider(chat.user_id).pipe(
										tap((details) => {
											if (details.annexe.ivf_clinic) {
												details.annexe.ivf_clinic.questionnaire.journey = this.ivfService.buildJourneyText(
													details.annexe.ivf_clinic.questionnaire,
												);
											}
										}),
								  )
								: of(null),
						),
				  );

		if (this.userType === 'admin') {
			this.providersService.getProviders('company_name', 'asc', 0, 100).subscribe((res) => (this.providers = res.data));
		}
		const lastOpened = this.chatService.lastOpened;
		if (lastOpened) {
			this.chatId$.pipe(first()).subscribe((chatId) => chatId || this.router.navigate(['/', 'chat', lastOpened]));
		}
		this.scrollTimer = interval(10000).subscribe(() => this.updateScrolling());
		this.listScrollSubject
			.pipe(
				filter(() => !this.skipScrollEvent),
				tap(() => (this.messageListScrolled = true)),
				filter(() => this.messagesListRef.nativeElement.scrollTop < window.screen.height),
				throttleTime(1000),
				switchMap(() => this.chatId$),
				filter((chatId) => !!chatId),
				tap(() => (this.isListUpdating = true)),
				exhaustMap((chatId) => this.chatService.loadMessages(chatId, true)),
				tap(() => (this.isListUpdating = false)),
			)
			.subscribe();
		this.chatService.loadChats().subscribe();
	}

	ngOnDestroy() {
		document.body.classList.remove('hide-hubspot');
		this.scrollTimer?.unsubscribe();
		this.listScrollSubject.complete();
	}
	getChatImg(chat: Chat) {
		return `images/${chat.egg_donor || chat.surrogate ? '' : `bi-sur/${chat.provider_id}/`}${
			chat.egg_donor?.img || chat.surrogate?.img || chat.provider?.surrogacy?.img
		}`;
	}

	useHeight(n: number | number[]) {
		const units = this.parentStore.getUnits();
		if (Array.isArray(n)) {
			return n.map((v) => this.utilService.useHeight(v, units)).join(' - ');
		}
		return this.utilService.useHeight(n, units);
	}
	useWeight(n: number) {
		return this.utilService.calculateWeight(n, this.parentStore.getUnits());
	}

	useArray(s: string[]) {
		if (!s) {
			return '-';
		}
		if (!Array.isArray(s)) {
			return s;
		}
		return (s || []).join(', ');
	}

	get chatReady() {
		const nativeSocket: WebSocket = (this.chatService.socket as any)._socket;

		const isOnline = typeof window.navigator.onLine === 'boolean' ? window.navigator.onLine : true;

		return isOnline && nativeSocket?.readyState === WebSocket.OPEN;
	}

	sendTooltip() {
		if (this.chatReady) {
			return undefined;
		}

		return 'Waiting for connection...';
	}
}
