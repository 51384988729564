<div class="info">
	We sent a verification code to <b>{{ phone }} </b>. Please enter it below.
</div>

<div class="title">Enter Code Below</div>

<mat-form-field appearance="outline">
	<app-verification-code-control [formControl]="codeControl"></app-verification-code-control>
</mat-form-field>

<div class="resend">
	<div *ngIf="timer$ | async as value; else resendActions" class="resend__timer">
		<mat-progress-bar [value]="(100 * value) / 60"></mat-progress-bar>
	</div>
	<ng-template #resendActions>
		<div class="info">Didn't get a code?</div>
		<div class="actions">
			<button mat-button type="button" (click)="resendCode('phone')">Resend code</button>
			<button mat-button type="button" (click)="resendCode('email')">Send via Email</button>
		</div>
	</ng-template>
</div>
