<div>
	<mat-checkbox color="primary" [checked]="!errors.has('minlength')"> At least 8 characters </mat-checkbox>
</div>
<div>
	<mat-checkbox color="primary" [checked]="!errors.has('capital')"> At least 1 capital letter </mat-checkbox>
</div>
<div>
	<mat-checkbox color="primary" [checked]="!errors.has('lower')"> At least 1 lowercase letter </mat-checkbox>
</div>
<div>
	<mat-checkbox color="primary" [checked]="!errors.has('number')"> At least 1 number </mat-checkbox>
</div>
