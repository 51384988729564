import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Query, Store, StoreConfig} from '@datorama/akita';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {ProviderType} from '@/config';

export interface AuthState {
	id: number;
	name: string;
	type: 'parent' | 'provider' | 'admin';
	with_donors: boolean;
	company_type: ProviderType[];
	ivf_id: number | null;
}

export const createInitialState = (): AuthState => ({
	id: null,
	name: '',
	type: null,
	with_donors: false,
	company_type: [],
	ivf_id: null,
});

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'auth'})
export class AuthStore extends Store<AuthState> {
	constructor() {
		super(createInitialState());
	}
}

@Injectable({providedIn: 'root'})
export class AuthQuery extends Query<AuthState> {
	isLoggedIn = this.select((state) => Boolean(state.name));
	constructor(protected store: AuthStore) {
		super(store);
	}
}

@Injectable({providedIn: 'root'})
export class AuthStoreService {
	public state = new BehaviorSubject<boolean>(null);
	public token = new BehaviorSubject<string>('');
	private user$: Observable<Record<string, any>>;

	constructor(private store: AuthStore, private query: AuthQuery, private http: HttpClient) {
		window['logout'] = () => {
			localStorage.clear();
			sessionStorage.clear();
			this.logout().subscribe(() => window.location.replace('/login'));
		};
	}

	get value() {
		return this.store.getValue();
	}

	get value$() {
		return this.query.select();
	}

	get loading() {
		return this.query.selectLoading().pipe(shareReplay(1));
	}

	update() {
		this.store.setLoading(true);

		return this.http.get<AuthUser & {token: string; contact_id?: number}>('/api/login').pipe(
			tap(
				(res) => {
					if (res.status === false) {
						this.store.update(createInitialState());
						this.state.next(false);
						this.store.setLoading(false);
						return;
					}
					this.store.update({
						id: res.contact_id || res.id,
						name: `${res.first_name} ${res.last_name}`,
						type: res.type,
						with_donors: res.with_donors,
						company_type: res.company_type || [],
						ivf_id: res.ivf_id || null,
					});
					this.token.next(res.token);
					this.state.next(true);
					this.store.setLoading(false);
				},
				(error) => {
					console.error(error);
					this.store.update(createInitialState());
					this.state.next(false);
					this.store.setLoading(false);
				},
			),
		);
	}

	async generateCaptchaToken(action: string) {
		await new Promise<void>((resolve) => grecaptcha.enterprise.ready(resolve));
		const token = await grecaptcha.enterprise.execute(environment.reCaptchaKey, {action});
		return token;
	}

	login(email: string, password: string) {
		this.store.setLoading(true);
		return from(this.generateCaptchaToken('LOGIN')).pipe(
			switchMap((token) =>
				this.http.post<AuthUser & {token: string; contact_id?: number}>('/api/login', {email, password, token}).pipe(
					tap(
						(res) => {
							this.store.update({
								id: res.contact_id || res.id,
								name: `${res.first_name} ${res.last_name}`,
								type: res.type,
								with_donors: res.with_donors,
								company_type: res.company_type || [],
								ivf_id: res.ivf_id || null,
							});
							this.token.next(res.token);
							this.state.next(true);
							this.store.setLoading(false);
						},
						(error) => {
							console.error(error);
							this.store.setLoading(false);
						},
					),
				),
			),
		);
	}

	resendCode(email: string, type: 'phone' | 'email') {
		return this.http.post('/api/user/resend-verify-code', {email, type});
	}

	verifyCode(email: string, code: string) {
		this.store.setLoading(true);
		return this.http.post<{first_name: string; last_name: string; type: 'parent'}>('/api/user/verify-code', {code, email}).pipe(
			tap({
				next: (res) => {
					this.store.update({name: `${res.first_name} ${res.last_name}`, type: res.type});
					this.state.next(true);
					this.store.setLoading(false);
				},
				error: () => {
					this.store.setLoading(false);
				},
			}),
		);
	}

	resetWithToken(token: string, password: string) {
		this.store.setLoading(true);
		return this.http.put('/api/reset', {token, password}).pipe(tap(() => this.store.setLoading(false)));
	}

	logout() {
		this.store.setLoading(true);
		this.user$ = null;
		return this.http.get('/api/logout', {responseType: 'text'}).pipe(
			tap(() => {
				this.store.update(createInitialState());
				if (this.state.value !== false) {
					this.state.next(false);
				}
				this.store.setLoading(false);
			}),
		);
	}

	getName() {
		return this.query.select('name');
	}

	getType() {
		return this.store.getValue().type;
	}

	reset(email: string) {
		this.store.setLoading(true);
		return this.http.post('/api/reset', {email}).pipe(tap(() => this.store.setLoading(false)));
	}

	check() {
		return this.query.isLoggedIn;
	}

	getUser() {
		if (this.user$) {
			return this.user$;
		}
		this.user$ = this.http
			.get<Record<string, any>>('/api/user')
			.pipe(
				shareReplay(1),
				map((res) => ({
					...res,
					birthday: res.birthday ? new Date(res.birthday) : res.birthday,
				} as Record<string, any>),
			));
		return this.user$;
	}

	getCalenadrConfig() {
		return this.http.get<Record<string, any>>('/api/v2/admin/calendarConfig');
	}

	updateCalenadrConfig(data: Record<string, unknown>) {
		return this.http.post<void>('/api/v2/admin/calendarConfig', data);
	}

	detachCalednar() {
		return this.http.post<void>('/api/v2/admin/detachCalendar', {});
	}

	getCalendarAuthUrl(type: string) {
		return this.http.get<{url: string}>('/api/v2/providers/calendar/get-auth-url', {params: {type}}).pipe(map((res) => res.url));
	}

	connectCalendar(data: {code: string; type: string}) {
		return this.http.post<{calendar_id: string}>('/api/v2/admin/saveAuthToken', data).pipe(map((res) => res.calendar_id));
	}

	updateUser(form: Record<string, any>) {
		this.user$ = null;
		return this.http.put('/api/user', form);
	}

	createProvider(form: Record<string, any>) {
		return this.http.post('/api/v2/providers', form);
	}

	checkProviderExists(company: string) {
		return this.http.get<{exists: boolean}>('/api/v2/providers/exists', {params: {company}});
	}

	checkProviderContactExists(email: string) {
		return this.http.get<{exists: boolean}>('/api/v2/providers/contacts/exists', {params: {email}});
	}

	updateProvider(form: Record<string, any>) {
		return this.http.put('/api/providers', form);
	}

	providerDetails() {
		return this.http.get('/api/v2/providers/details');
	}

	createSurrogacyUser(form: Record<string, any>) {
		return this.http.post(
			'/api/user/surrogacy',
			{
				...form,
				token: '',
			},
			{observe: 'response', responseType: 'text'},
		);
	}

	createEggDonationUser(form: Record<string, any>) {
		return this.http.post(
			'/api/users',
			{
				...form,
				token: '',
			},
			{observe: 'response', responseType: 'text'},
		);
	}
}

interface AuthUser {
	id: number;
	first_name: string;
	last_name: string;
	status: boolean;
	type: 'parent' | 'provider' | 'admin';
	with_donors?: boolean;
	company_type?: ProviderType[];
	ivf_id?: number | null;
}
