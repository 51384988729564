import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Query, Store, StoreConfig} from '@datorama/akita';
import {DateTime} from 'luxon';
import {forkJoin, Observable} from 'rxjs';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';
import {IFilters as IvfFilters, IvfService} from '@/screens/ivf/ivf.service';

import {ProviderType} from '../config';
import {AnalyticsService} from './analytics.service';
import {ItemType, UtilsService} from './utils.service';
import {fromPairs} from 'lodash-es';
// ? probably temporary fix
export {ItemType};

type Questionnaire = Record<ProviderType, boolean> & {
	answeredWhenQuestion: Record<ProviderType, boolean>;
	hasPendingAppointmentAnswers: Array<{
		providerId: number;
		providerName: string;
		appointmentId: number;
	}>;
	heardFrom: string;
};

export interface ParentState {
	donorFilters: {[key: string]: any};
	surrogacyFilters: {[key: string]: any};
	surrogateFilters: {[key: string]: any};
	donorScroll: number;
	surrogacyScroll: number;
	surrogateScroll: number;
	favouritesMap: Record<ProviderType, number[]>;
	comparingMap: Record<ProviderType, number[]>;
	donorShowFavourites: boolean;
	surrogacyShowFavourites: boolean;
	surrogatesShowFavourites: boolean;
	appointments: Appointment[];
	donorSorting: string;
	surrogacySorting: string;
	surrogateSorting: string;
	interested: number[];
	questionnaire: Questionnaire;
	ui: {
		showFilters: boolean;
		showFiltersSurrogacy: boolean;
		showFiltersSurrogates: boolean;
		isInitialFiltersLoading: boolean;
	};
}

export interface Annexe {
	id: number;
	type: ProviderType;
	value: Record<string, unknown>;
	questionnaire: Record<string, unknown>;
	favourites: number[];
	created_at: Date;
}

const getTypeMap = () => fromPairs(Object.values(ProviderType).map((type) => [type, []])) as Record<ProviderType, number[]>;

export const createInitialState = (): ParentState => ({
	donorFilters: {},
	surrogacyFilters: {},
	surrogateFilters: {},
	donorScroll: 0,
	surrogacyScroll: 0,
	surrogateScroll: 0,
	favouritesMap: getTypeMap(),
	comparingMap: getTypeMap(),
	appointments: [],
	donorShowFavourites: false,
	surrogacyShowFavourites: false,
	surrogatesShowFavourites: false,
	donorSorting: 'Date Added',
	surrogacySorting: 'rating',
	surrogateSorting: 'Date Added',
	interested: [],
	questionnaire: {
		answeredWhenQuestion: null,
		hasPendingAppointmentAnswers: [],
	} as Questionnaire,
	ui: {
		showFilters: true,
		showFiltersSurrogacy: true,
		showFiltersSurrogates: true,
		isInitialFiltersLoading: false,
	},
});

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'parent'})
export class ParentStore extends Store<ParentState> {
	constructor() {
		super(createInitialState());
	}
}

@Injectable({providedIn: 'root'})
export class ParentQuery extends Query<ParentState> {
	constructor(protected store: ParentStore) {
		super(store);
	}
}

@Injectable({providedIn: 'root'})
export class ParentStoreService {
	showFilters$ = this.query.select((state) => state.ui.showFilters);
	showFiltersSurrogacy$ = this.query.select((state) => state.ui.showFiltersSurrogacy);
	showFiltersSurrogates$ = this.query.select((state) => state.ui.showFiltersSurrogates);
	isInitialFiltersLoading$ = this.query.select((state) => state.ui.isInitialFiltersLoading);

	private nthOfPopups = new Set<string>();

	constructor(
		private store: ParentStore,
		private query: ParentQuery,
		private http: HttpClient,
		private utils: UtilsService,
		private analytics: AnalyticsService,
		private ivfService: IvfService,
	) {}

	get loading() {
		return this.query.selectLoading().pipe(shareReplay(1));
	}

	get favourites() {
		return this.query.select('favouritesMap').pipe(map((item) => item[ProviderType.EGG_AGENCY] || []));
	}
	get favouriteSurrogacies() {
		return this.query.select('favouritesMap').pipe(map((item) => item[ProviderType.SURROGACY_AGENCY] || []));
	}

	get favouriteSurrogates() {
		return this.query.select('favouritesMap').pipe(map((item) => item[ProviderType.SURROGATES_AGENCY] || []));
	}

	get answeredWhenQuestion$() {
		return this.query.select('questionnaire').pipe(map((data) => data?.answeredWhenQuestion));
	}

	get pendingAppointmentsAnswers$() {
		return this.query.select('questionnaire').pipe(map((data) => data?.hasPendingAppointmentAnswers));
	}

	get heardFrom$() {
		return this.query.select('questionnaire').pipe(map((data) => data?.heardFrom));
	}

	get isPopupOpened() {
		return this.nthOfPopups.size !== 0;
	}

	openPopup(id: string) {
		this.nthOfPopups.add(id);
	}

	closePopup(id: string) {
		this.nthOfPopups.delete(id);
	}

	clear() {
		this.store.update(createInitialState());
	}

	update() {
		this.store.setLoading(true);
		this.store.update((state) => ({
			ui: {
				...state.ui,
				isInitialFiltersLoading: true,
			},
		}));
		return forkJoin([
			this.http.get('/api/users/favourites').pipe(
				tap(
					(data: {favorites: number[]; surrogacy_favorites: number[]; ivf_favorites: number[]; surrogates_favorites: number[]}) => {
						this.store.update((state) => ({
							favouritesMap: {
								...state.favouritesMap,
								[ProviderType.EGG_AGENCY]: data.favorites || [],
								[ProviderType.SURROGACY_AGENCY]: data.surrogacy_favorites || [],
								[ProviderType.IVF_CLINIC]: data.ivf_favorites || [],
								[ProviderType.SURROGATES_AGENCY]: data.surrogates_favorites || [],
							},
						}));
					},
					(error) => {
						console.error(error);
					},
				),
			),
			this.http.get('/api/users/filters').pipe(
				tap(
					(data: {[key: string]: any}) => {
						this.store.update({donorFilters: data});
					},
					(error) => {
						console.error(error);
						this.store.update({donorFilters: {}});
					},
				),
			),
			this.http.get('/api/users/filters?type=surrogacy').pipe(
				tap(
					(data: {[key: string]: any}) => {
						this.store.update({surrogacyFilters: data});
					},
					(error) => {
						console.error(error);
						this.store.update({surrogacyFilters: {}});
					},
				),
			),
			this.http.get('/api/users/filters?type=surrogates').pipe(
				tap(
					(data: {[key: string]: any}) => {
						this.store.update({surrogateFilters: data});
					},
					(error) => {
						console.error(error);
						this.store.update({surrogacyFilters: {}});
					},
				),
			),
			this.http.get<IvfFilters>('/api/users/filters?type=ivf').pipe(
				tap(
					(data) => {
						if (data && Object.keys(data).length) {
							this.ivfService.updateLocalFilters(this.ivfService.mapFilters(data));
						}
					},
					(error) => {
						console.error(error);
					},
				),
			),
			this.http.get<number[]>('/api/users/interested-in').pipe(tap((data) => this.store.update({interested: data}))),
			this.checkUserQuestionnaire().pipe(
				map((data) => ({...data, heardFrom: data.heardFrom || ''})),
				tap((data) => this.store.update({questionnaire: data})),
			),
		]).pipe(
			tap(() => {
				this.store.setLoading(false);
				this.store.update((state) => ({
					ui: {
						...state.ui,
						isInitialFiltersLoading: false,
					},
				}));
			}),
			catchError((error) => {
				this.store.setLoading(false);
				throw error;
			}),
		);
	}

	setSorting(sorting: string, type: ItemType) {
		this.store.update({[`${type}Sorting`]: sorting});
	}

	getSorting(type: ItemType) {
		return this.query.select(`${type}Sorting`);
	}

	setShowFilters(state: boolean = null, type: ItemType) {
		const current = this.store.getValue().ui;
		switch (type) {
			case ItemType.DONOR:
				this.store.update({
					ui: {
						...current,
						showFilters: state === null ? !current.showFilters : state,
					},
				});
				break;
			case ItemType.SURROGACY:
				this.store.update({
					ui: {
						...current,
						showFiltersSurrogacy: state === null ? !current.showFiltersSurrogacy : state,
					},
				});
				break;
			case ItemType.SURROGATE:
				this.store.update({
					ui: {
						...current,
						showFiltersSurrogates: state === null ? !current.showFiltersSurrogates : state,
					},
				});
				break;

		}
	}

	showFavourites(state: boolean, type: ItemType) {
		const key = `${type}ShowFavourites`;
		if (this.query.getValue()[key] !== state) {
			this.store.update({[key]: state});
		}
	}

	getShowFavourites(type: ItemType) {
		const key = `${type}ShowFavourites` as keyof ParentState;
		return this.query.select(key) as Observable<boolean>;
	}

	toggleInFavourites(id: number, type: ProviderType) {
		const current = this.query.getValue().favouritesMap[type].slice();
		const index = current.indexOf(id);
		if (index === -1) {
			current.push(id);
		} else {
			current.splice(index, 1);
		}
		this.store.update((state) => ({
			favouritesMap: {
				...state.favouritesMap,
				[type]: current,
			},
		}));
		this.http
			.put(
				'/api/users/favourites',
				{favourites: current},
				{
					params: {
						type: {
							[ProviderType.IVF_CLINIC]: 'ivf',
							[ProviderType.EGG_AGENCY]: 'donor',
							[ProviderType.SURROGACY_AGENCY]: 'surrogacy',
						}[type] || type,
					},
				},
			)
			.subscribe();
	}

	isInFavourites(id: number, type: ProviderType): Observable<boolean> {
		return new Observable((subs) => {
			let previous = null;
			const internal = this.query
				.select('favouritesMap')
				.pipe(map((items) => items[type]))
				.subscribe((value) => {
					const current = Boolean((value || []).includes(id));
					if (previous !== current) {
						subs.next(current);
					}
					previous = current;
				});
			return {
				unsubscribe: () => {
					subs.unsubscribe();
					subs.complete();
					internal.unsubscribe();
				},
			};
		});
	}

	addToCompare(id: number, type: ProviderType) {
		const current = (this.query.getValue().comparingMap[type] || []).slice();
		if (current.length < 5) current.push(id);
		this.store.update((state) => ({
			comparingMap: {
				...state.comparingMap,
				[type]: current,
			},
		}));
	}

	removeFromCompare(id: number, type: ProviderType) {
		if (id === null) {
			this.store.update((state) => ({
				comparingMap: {
					...state.comparingMap,
					[type]: [],
				},
			}));
		} else {
			const current = this.query.getValue().comparingMap[type].slice();
			current.splice(current.indexOf(id), 1);
			this.store.update((state) => ({
				comparingMap: {
					...state.comparingMap,
					[type]: current,
				},
			}));
		}
	}

	toggleInCompare(id: number, type: ProviderType) {
		const current = (this.query.getValue().comparingMap[type] || []).slice();
		const index = current.indexOf(id);
		if (index === -1) {
			if (current.length < 5) current.push(id);
		} else current.splice(index, 1);
		this.store.update((state) => ({
			comparingMap: {
				...state.comparingMap,
				[type]: current,
			},
		}));
	}

	getCompare(type: ProviderType) {
		return this.query.select('comparingMap').pipe(map((items) => items[type]));
	}

	isInCompare(id: number, type: ProviderType): Observable<boolean> {
		return new Observable((subs) => {
			let previous = null;
			const internal = this.query
				.select('comparingMap')
				.pipe(map((items) => items[type]))
				.subscribe((value) => {
					const current = Boolean((value || []).includes(id));
					if (previous !== current) {
						subs.next(current);
					}
					previous = current;
				});
			return {
				unsubscribe: () => {
					subs.unsubscribe();
					subs.complete();
					internal.unsubscribe();
				},
			};
		});
	}

	getFilters(type: ItemType) {
		return this.query.getValue()[`${type}Filters`];
	}

	getUnits(): 'imperial' | 'metric' {
		return this.query.getValue().donorFilters?.units || 'imperial';
	}

	getFiltersSubscription(type: ItemType) {
		return this.query.select(`${type}Filters`);
	}

	getPage(type: ItemType) {
		return this.query.getValue()[`${type}Scroll`];
	}

	setFilters(filters, type: ItemType) {
		this.store.update({
			[`${type}Filters`]: filters
				? (this.utils.mapFilters(filters, type) as {[key: string]: any})
				: null,
		});
	}

	setPage(scroll: number, type: ItemType) {
		this.store.update({[`${type}Scroll`]: scroll});
	}

	isInterestedIn(id: number) {
		return this.query.select('interested').pipe(map((res) => res.includes(id)));
	}

	setInterestedIn(id: number) {
		const interested = this.store.getValue().interested.slice();
		interested.push(id);
		this.store.update({interested});
	}

	emitCompareEvent(type: ProviderType) {
		return this.http.put('/api/user/compare-event', {}, {params: {type}});
	}

	isInAppointmentTime(surrogacy_id: number) {
		return this.query.select('appointments').pipe(map((res) => res.find((item) => item.surrogacy_id === surrogacy_id)));
	}

	checkUserQuestionnaire() {
		return this.http.get<Questionnaire>('/api/user/questionnaire');
	}

	getUserQuestionnaire() {
		return this.store.getValue().questionnaire;
	}

	updateUserQuestionnaire(form: Record<string, any>, type: ProviderType) {
		return this.http.put<void>('/api/user/questionnaire', form, {params: {type}});
	}

	updateWhenQuestion(type: ProviderType, value: string) {
		return this.http.post('/api/user/update-when-question', {type, value}).pipe(
			tap(() =>
				this.store.update((state) => ({
					...state,
					questionnaire: {
						...state.questionnaire,
						answeredWhenQuestion: {
							...(state.questionnaire.answeredWhenQuestion || ({} as Record<ProviderType, boolean>)),
							[type]: !!value,
						},
					},
				})),
			),
		);
	}

	updateAppointmentRating(appointmentId: number, rating: number) {
		return this.http.post('/api/user/update-appointment-rating', {appointmentId, rating}).pipe(
			tap(() =>
				this.store.update((state) => ({
					...state,
					questionnaire: {
						...state.questionnaire,
						hasPendingAppointmentAnswers: state.questionnaire.hasPendingAppointmentAnswers.filter(
							(item) => item.appointmentId !== appointmentId,
						),
					},
				})),
			),
		);
	}

	updateHeardFrom(response: string) {
		return this.http.post('/api/user/update-heard-from', {response}).pipe(
			tap(() =>
				this.store.update((state) => ({
					...state,
					questionnaire: {
						...state.questionnaire,
						heardFrom: response,
					},
				})),
			),
		);
	}
}

export interface Appointment {
	id: number;
	surrogacy_id: number;
	appointment_time: DateTime;
	status: string;
	created_at: DateTime;
}
