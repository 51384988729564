import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {cloneDeep} from 'lodash-es';
import {Observable, of} from 'rxjs';
import {distinctUntilChanged, first, map, shareReplay, tap} from 'rxjs/operators';
import {Insurance, NIvfFilters} from '@/models/ivf.model';

import {Store} from '../../services/store';
import {options} from './questions';

export interface IFilters {
	country: string;
	state: string;
	city: string;
	zip: string;
	coordinates: [number, number];

	fullAddress: string;

	favouritesOnly: boolean;
	search_by: string;
	services: Set<NIvfFilters.IvfService>;

	sort: NIvfFilters.IvfSort;
	order: 'ASC' | 'DESC';

	questionnaire: Record<string, any>;

	insurance: Insurance;
}

const getInitialFilters = (): IFilters => ({
	country: null,
	state: null,
	city: null,
	zip: null,
	coordinates: null,

	fullAddress: '',

	favouritesOnly: false,
	search_by: '',
	services: new Set<NIvfFilters.IvfService>(),

	sort: NIvfFilters.IvfSort.Date,
	order: 'DESC',
	insurance: {
		type: null,
		insurance: {
			carrier: null,
			plan: null,
		},
	},

	questionnaire: null,
});

export interface IvfState {
	loading: boolean;
	profiles: Record<number, Record<string, any>>;
	filters: IFilters;
}

const createInitialState = (): IvfState => ({
	loading: false,
	profiles: {},
	filters: getInitialFilters(),
});

@Injectable({providedIn: 'root'})
export class IvfService extends Store<IvfState> {
	public suffixes = ['Any_35', '35_37', '38_40', '41_42', '42_Any'];
	public avgSuffix = '_avg';

	constructor(private http: HttpClient) {
		super(createInitialState());
	}

	createProfile(data: Record<string, any>) {
		return this.http.post('/api/ivf/createProfile', data, {observe: 'response', responseType: 'text'});
	}

	reset() {
		this.set(createInitialState());
	}

	filter(filters: Record<string, any>) {
		return this.http
			.post<{results: Record<string, any>[]; count: number}>('/api/ivf/filter', filters)
			.pipe(tap(() => this.update({loading: false})));
	}

	getOne(id = 0, forceReload = false) {
		if (forceReload || !this.state.profiles[id]) {
			return this.http.get<Record<string, any>>(`/api/ivf/profile/${id}`).pipe(
				tap((profile) =>
					this.update({
						profiles: {
							...this.state.profiles,
							[id]: cloneDeep(profile),
						},
					}),
				),
			);
		} else {
			return this.state$.pipe(
				map((state) => state.profiles[id]),
				distinctUntilChanged(),
			);
		}
	}

	getNational() {
		return this.getOne().pipe(shareReplay(1), first());
	}

	getDistances<T extends Array<Record<string, any> & {distance: number}>>(
		id: number,
		coordinates: [lng: number, lat: number] = null,
	): Observable<T> {
		return this.http
			.get<{results: T}>(`/api/ivf/distance/${id}`, {
				params: coordinates && {
					lng: coordinates[0],
					lat: coordinates[1],
				},
			})
			.pipe(
				map(({results}) => results),
				tap((results) =>
					this.update({
						profiles: {
							...this.state.profiles,
							[id]: this.state.profiles[id] && {
								...this.state.profiles[id],
								location: results,
							},
						},
					}),
				),
			);
	}

	prepareImage<T extends Record<string, any>>(ivf: T): T {
		if (!ivf) {
			return ivf;
		}

		(ivf as Record<string, any>).img =
			ivf.img ||
			`https://ui-avatars.com/api/?length=2&size=90&font-size=0.3&rounded=true&bold=true&background=c4aedc&color=ffffff&name=${ivf.name}`;

		return ivf;
	}

	prepareName<T extends Record<string, any>>(ivf: T): T {
		if (ivf?.name) {
			const match = ivf.name.match(/([^,\-]*)([,\-]\s+)(.*)/);
			if (match && match[3].length > 5) {
				(ivf as Record<string, any>).actualName = [match[1], match[3]];
			} else {
				(ivf as Record<string, any>).actualName = [ivf.name];
			}
		} else {
			(ivf as Record<string, any>).actualName = [];
		}

		return ivf;
	}

	prepareCost<T extends Record<string, any>>(ivf: T): T {
		(ivf as Record<string, any>).cost = [];

		if (ivf.provider?.providers_costs?.length) {
			const cost = ivf.provider.providers_costs[0];

			if (cost.value) {
				ivf.cost.push(cost.value);
				if (cost.max_value) {
					ivf.cost.push(cost.max_value);
				}
			} else if (cost.special_value) {
				const [_, min = '0', max = '0'] = cost.special_value.match(/(\d+)-(\d+)/) || [];
				ivf.cost.push(+min, +max);
			}
		}

		return ivf;
	}

	createProfileLink(ivf: Record<string, any>) {
		const slug = ivf.name
			.toLowerCase()
			.replace(/ /g, '-')
			.replace(/[^a-z\-]/g, '');
		return `/ivf/profile/${ivf.provider?.id}/${ivf.id}/${slug}`;
	}

	createEditProfileLink(ivf: Record<string, any>) {
		const slug = ivf.name
			.toLowerCase()
			.replace(/ /g, '-')
			.replace(/[^a-z\-]/g, '');
		return `/ivf/profile/edit/${ivf.provider?.id}/${ivf.id}/${slug}`;
	}

	updateProfile(id: number, profileToUpdate: Record<string, any>) {
		return this.http.post(`/api/ivf/update/${id}`, profileToUpdate);
	}

	updateLocalFilters(filters: IFilters) {
		this.update({
			filters: {
				...cloneDeep(filters),
				fullAddress: [filters.zip, filters.city, filters.state, filters.country].filter(Boolean).join(', '),
			},
		});
	}

	mapFilters(filters: Record<string, any>): IFilters {
		return {
			country: filters.country || null,
			state: filters.state || null,
			city: filters.city || null,
			zip: filters.zip || null,
			coordinates: filters.coordinates,

			fullAddress: [filters.zip, filters.city, filters.state, filters.country].filter(Boolean).join(', '),

			favouritesOnly: filters.favouritesOnly,
			search_by: filters.search_by || '',
			services: new Set(filters.services),

			sort: filters.sort || NIvfFilters.IvfSort.Date,
			order: filters.order,
			insurance: filters.insurance,

			questionnaire: {
				...(filters.questionnaire || {}),
				...(filters.questions || {}),
			},
		};
	}

	listClinicNames() {
		return this.http.get<{results: Array<{id: number; name: string}>}>('/api/ivf/names').pipe(map(({results}) => results));
	}

	buildJourneyText(journey: Record<string, unknown>) {
		const buildingFamily = journey.buildingFamily as NIvfFilters.BuildingFamily;

		if (buildingFamily === NIvfFilters.BuildingFamily.Freezing) {
			return `I am looking to <span>Freeze My Eggs</span> and I am <span>${journey.eggsOwnerAge}</span> years-old`;
		} else {
			const carrierValue = journey.carrier as NIvfFilters.PregnancyCarreir;
			const carrier = options.carrier.find((item) => item.value === carrierValue);

			const usingValue = journey.eggsOwner as NIvfFilters.EggsOwner;
			const using = options.eggsOwner.find((item) => item.value === usingValue);

			const treatmentValue = journey.inVirto as NIvfFilters.InVirto;
			const treatment = treatmentValue === NIvfFilters.InVirto.Yes;
			const eggsOwnerAgeValue = journey.eggsOwnerAge;
			return `I am looking for <span>Live Birth Pregnancy</span> carried by <span>${carrier?.label}</span> using <span>${
				using?.label
			}</span>${eggsOwnerAgeValue ? `from a <span>${eggsOwnerAgeValue}</span> year-old` : ''} who <span>${
				treatment ? 'has' : 'has not'
			}</span> received fertility treatment`;
		}
	}
}
