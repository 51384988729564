import { Component, Output, EventEmitter, Input, HostListener, OnInit, OnDestroy } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subscription } from 'rxjs';

import { UtilsService } from 'src/app/services/utils.service';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, OnDestroy {
	@Input()
	checked = null;
	@Input()
	size = 24;
	@Input()
	set withHovering(value: boolean) {
		this._withHovering = coerceBooleanProperty(value);
	}
	_withHovering = false;
	@Input()
	set withBorder(value: boolean) {
		this._withBorder = coerceBooleanProperty(value);
	}
	_withBorder = true;

	@Input()
	value: boolean;
	@Output()
	valueChange = new EventEmitter<boolean>();

	get isHovered() {
		return this._withHovering && this._isHovered;
	}
	_isHovered = false;

	smallScreen = false;
	smallScreenSubscription: Subscription;

	@HostListener('mouseenter')
	onHover() {
		this._isHovered = !this.smallScreen;
	}
	@HostListener('mouseleave')
	onHoverOff() {
		this._isHovered = false;
	}

	constructor(
		private utilService: UtilsService
	) { }

	onChange() {
		this.value = !this.value;
		this.valueChange.emit(this.value);
	}

	ngOnInit() {
		this.smallScreenSubscription = this.utilService.isSmallScreen.subscribe((value) => this.smallScreen = value);
	}

	ngOnDestroy() {
		this.smallScreenSubscription.unsubscribe();
	}
}
