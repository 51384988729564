import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {cloneDeep, isEqual} from 'lodash-es';
import {combineLatest, Subscription} from 'rxjs';
import {distinctUntilChanged, first, map, skipWhile, switchMapTo, tap} from 'rxjs/operators';
import {ProviderType} from '@/config';
import {AuthStoreService} from '@/services/auth.service';
import {InfiniteSource} from '@/services/infinite.datasource';
import {ParentStoreService} from '@/services/parent.service';
import {UtilsService} from '@/services/utils.service';
import {IFilters, IvfService} from './ivf.service';
import {ConciergeFtuPopupComponent} from "@/components/concierge-ftu-popup/concierge-ftu-popup.component";
import {setLastProduct} from "@/app-routing.module";
import {GoStorkProducts} from "@/consts/config";

@Component({
	selector: 'app-ivf',
	templateUrl: './ivf.component.html',
	styleUrls: ['./ivf.component.scss'],
})
export class IvfComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('filtersRef')
	filtersContainer: ElementRef<HTMLElement>;
	@ViewChild('dialogTemplateRef')
	dialogTemplateRef: TemplateRef<void>;

	dialogRef: MatDialogRef<any>;

	@ViewChild(ConciergeFtuPopupComponent)
	conciergeFtuPopupComponent: ConciergeFtuPopupComponent;

	isInitialLoading$ = this.parentStore.isInitialFiltersLoading$;

	isSmallScreen$ = this.utilService.isSmallScreen;
	smallFilters = false;
	showConciergeFtu = true;
	lastScrollTop = 0;

	filters: IFilters;
	_previous_filters: IFilters;
	filtersSubscription: Subscription;

	dataSource = new InfiniteSource(
		(start, end) =>
			this.filters &&
			this.ivfService
				.filter({
					...this.mapFiltersToRequest(this.ivfService.state.filters),
					take: end - start,
					skip: start,
				})
				.pipe(
					tap(() => {
						if (start === 0) {
							this.lastScrollTop = window.scrollY;
							this.smallFilters = false;
						}
					}),
				),
	);

	constructor(
		private utilService: UtilsService,
		private ivfService: IvfService,
		private authService: AuthStoreService,
		private route: ActivatedRoute,
		private parentStore: ParentStoreService,
		private router: Router,
		private dialog: MatDialog,
	) {}

	@HostListener('window:scroll')
	onScroll() {
		const delta = window.scrollY - this.lastScrollTop;
		if (window.scrollY > 100 && Math.abs(delta) > 40) {
			const scrollingDown = delta > 0;
			this.lastScrollTop = window.scrollY;
			this.smallFilters = scrollingDown;
		} else if (window.scrollY < 100 && this.smallFilters) {
			this.smallFilters = false;
		}
	}

	updateQuestionnaire(id: string, value: any) {
		this.filters = {
			...this.ivfService.state.filters,
			questionnaire: {
				...this.filters.questionnaire,
				[id]: value,
			},
		};
		this.ivfService.updateLocalFilters(this.filters);
	}

	mapFiltersToRequest(filters: IFilters): Record<string, any> {
		return {
			favouritesOnly: filters.favouritesOnly,
			order: filters.order,
			search_by: filters.search_by || null,
			services: Array.from(filters.services),
			sort: filters.sort,

			country: filters.country || null,
			state: filters.state || null,
			city: filters.city || null,
			zip: filters.zip || null,

			questions: filters.questionnaire,

			coordinates: filters.coordinates,

			insurance: filters.insurance,
			// showAverageOrAge: filters.showAverageOrAge,
		};
	}

	highlightFilters() {
		this.filtersContainer.nativeElement.classList.add('highlighted');
		setTimeout(() => this.filtersContainer.nativeElement.classList.remove('highlighted'), 500);
	}

	ngOnInit(): void {
		combineLatest([this.authService.loading, this.parentStore.loading])
			.pipe(
				skipWhile(([l1, l2]) => l1 || l2),
				switchMapTo(this.route.queryParams),
				first(),
			)
			.subscribe((params) => {
				const userType = this.authService.getType();
				const isLoggedIn = ['admin', 'parent'].includes(userType);
				if (isLoggedIn) {
					const questionnaire = this.parentStore.getUserQuestionnaire();

					if (!questionnaire[ProviderType.IVF_CLINIC]) {
						this.showConciergeFtu = false;
						return this.router.navigate(['./questionnaire/1'], {relativeTo: this.route});
					} else {
						this.showConciergeFtu = true;
					}

					this.filters = cloneDeep(this.ivfService.state.filters);

					if (params.insuranceType) {
						this.filters.insurance = {
							type: params.insuranceType,
							insurance: {
								carrier: params.insuranceCarrier || null,
								plan: params.insurancePlan || null,
							},
						};
					}

					this._previous_filters = cloneDeep(this.filters);
					setTimeout(() => this.dataSource.refresh(), 0);
				} else {
					this.router.navigate(['./questionnaire/1'], {relativeTo: this.route});
				}
			});
		this.filtersSubscription = this.ivfService.state$
			.pipe(
				map((state) => state.filters),
				distinctUntilChanged((a, b) => isEqual(a, b)),
			)
			.subscribe((filters) => {
				console.log('[IvfComponent] updating filters');
				this.filters = filters;
				setTimeout(() => this.dataSource.refresh(), 0);
			});
	}

	closeDialog() {
		this.dialogRef.close();
		this.conciergeFtuPopupComponent.show();
	}

	ngAfterViewInit() {
		setLastProduct(GoStorkProducts.IVF);
		this.route.queryParams.pipe(first()).subscribe((params) => {
			if ('firstTime' in params) {
				this.dialogRef = this.dialog.open(this.dialogTemplateRef, {
					maxWidth: 500,
				});
				this.router.navigate([], {
					relativeTo: this.route,
					queryParams: {
						...params,
						firstTime: null,
					},
					queryParamsHandling: 'merge',
				});
			} else {
				if (this.showConciergeFtu) {
					this.conciergeFtuPopupComponent.readyToShow();
				}
			}
		});
	}

	ngOnDestroy() {
		this.filtersSubscription?.unsubscribe();
		this.dataSource.destroy();
	}
}
